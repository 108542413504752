import AccountPeriod from 'components/entityManagement/accounts/maintain/AccountPeriod';
import Dashboard from 'components/Dashboard';
import SecureRoute from 'components/routing/SecureRoute';
import UnderConstruction from 'components/UnderConstruction';
import CreateAccount from 'pages/account/CreateAccount';
import MaintainAccount from 'pages/account/MaintainAccount';
import AddUser from 'pages/admin/AddUser';
import AddUsers from 'pages/admin/AddUsers';
import BulkUserUpload from 'pages/admin/BulkUserUpload';
import ChannelDetailsPage from 'pages/admin/channelDetailsPage';
import ConfigurationTool from 'pages/admin/ConfigurationTool';
import ConfigureId from 'pages/admin/ConfigureId';
import ConfigGroupEditor from 'pages/admin/ConfigGroupEditor';
import ManageReusableContent from 'pages/admin/correspondence/ManageReusableContent';
import ManageCorrespondenceType from 'pages/admin/correspondence/ManageCorrespondenceType';
import ReusableContent from 'pages/admin/correspondence/ReusableContents';
import CorrespondenceTypes from 'pages/admin/correspondence/CorrespondenceTypes';
import CreateChannel from 'pages/admin/createChannel/CreateChannel';
import CreateForm from 'pages/admin/CreateForms/CreateForm';
import DataMapperTest from 'pages/admin/DataMapper/DataMapperPage';
import EditForm from 'pages/admin/EditForm';
import EditUserRoles from 'pages/admin/EditUserRoles';
import ManageScheduledTasks from 'pages/admin/manageScheduledTasks/ManageScheduledTasksDashboard';
import FillForm from 'pages/admin/FillForm';
import LogixTest from 'pages/admin/LogixTestHarness/LogixPage';
import ManageChannel from 'pages/admin/ManageChannel';
import ManageForms from 'pages/admin/ManageForms/ManageForms';
import ManageRoles from 'pages/admin/manageRoles/ManageRole';
import ManageUserRoles from 'pages/admin/ManageUserRoles';
import PolicyGroup from 'pages/admin/PolicyGroup/PolicyGroup';
import ManagePolicyGroups from 'pages/admin/ManagePolicyGroups/ManagePolicyGroups';
import ScheduledTasks from 'pages/admin/createScheduledTasks/ScheduledTasks';
import Tenant from 'pages/admin/Tenant';
import TenantConfig from 'pages/admin/TenantConfig';
import TenantList from 'pages/admin/TenantList';
import WorkflowEditor from 'pages/admin/WorkflowEditor';
import CreateAsset from 'pages/asset/CreateAsset';
import MaintainAsset from 'pages/asset/MaintainAsset';
import BatchDetails from 'pages/BatchDetails';
import BillDetails from 'pages/BillDetails';
import CreateBatch from 'pages/CreateBatch';
import CreateDeposit from 'pages/CreateDeposit';
import DepositDetails from 'pages/DepositDetails';
import ActiveDictionary from 'pages/dictionary/ActiveDictionary/ActiveDictionary';
import EditDictionary from 'pages/dictionary/EditDictionary/EditDictionary';
import CreateEntity from 'pages/entity/CreateEntity';
import MaintainEntity from 'pages/entity/MaintainEntity';
import ManageFavorites from 'pages/Favorites';
import FinancialTransaction from 'pages/FinancialTransaction';
import FormEntry from 'pages/forms/FormEntry';
import ManageBatchesProperty from 'pages/ManageBatches/ManageBatchesProperty';
import ManageBatchesRevenue from 'pages/ManageBatches/ManageBatchesRevenue';
import ManageDepositsProperty from 'pages/ManageDeposits/ManageDepositsProperty';
import ManageDepositsRevenue from 'pages/ManageDeposits/ManageDepositsRevenue';
import ManageTaxingAuthority from 'pages/ManageTaxingAuthority';
import TaxingAuthorityView from 'pages/ManageTaxingAuthority/TaxingAuthorityView';
import Navigation from 'pages/Navigation';
import PaymentDetails from 'pages/PaymentDetails';
import PropertyView from 'pages/PropertyView';
import Search from 'pages/Search';
import Settings from 'pages/Settings';
import ViewFinancialTransGroup from 'pages/ViewFinancialTransGroup';
import Workflows from 'pages/Workflows';
import CreateWorkflow from 'pages/Workflows/Create';
import UpdateWorkflow from 'pages/Workflows/Update';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import Error from 'components/Error';
import ApprovalsPage from 'pages/Approvals/ApprovalsPage';
import DataMapperTestV2 from 'pages/admin/DataMapperPageV2';
import SinkObjects from 'pages/admin/SinkObjects';
import TaskDetailsPage from 'pages/admin/taskDetailsPage/TaskDetailsPage';
import StagesDetailsPage from 'pages/admin/taskDetailsPage/stagesDetailsPage/StagesDetailsPage';
import CustomerList from 'pages/admin/CustomerList/CustomerList';
import LayoutBuilderSandbox from 'pages/admin/LayoutBuilderSandbox';
import LetterDetails from 'components/correspondenceAndHeaderFooter/letter/LetterDetails';
import BusinessPolicy from 'pages/admin/BusinessPolicy';
import BusinessPolicies from 'pages/admin/BusinessPolicies';
import Reports from 'pages/Reports';
import ReportsDetails from 'components/ReportsDetails';
import PowerBi from 'components/PowerBi';
import PowerBiDashboards from 'components/PowerBiDashboards';
import Dashboards from 'pages/Dashboards';
import Roles from 'pages/admin/Roles';
import Adjustment from 'pages/Adjustment/Adjustment';
import BankAccountDetails from 'pages/bankAccount/BankAccountDetails';
import PrintManagementPage from 'components/correspondenceAndHeaderFooter/PrintManagement/PrintManagementPage';
import RefundBatchDetails from 'pages/refundBatch/RefundBatchDetails';
import ViewForms from 'pages/forms/ViewForms';
import FormView from 'pages/forms/FormView';
import RefundBatchSummary from 'pages/refundBatch/RefundBatchSummary/RefundBatchSummary';
import SubmissionDetails from 'pages/SubmissionDetails';
import FormSuspense from 'pages/forms/FormSuspense';

function AppRoutes(): JSX.Element {
  const module = useAppSelector((state) => state.user.module);
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <SecureRoute
            componentName="dashboard"
            Component={<Navigate to={`/${module}/dashboard`} />}
          />
        }
      />
      <Route
        path={'/dashboard'}
        element={
          <SecureRoute
            componentName="dashboard"
            Component={<Navigate to={`/${module}/dashboard`} />}
          />
        }
      />
      <Route
        path={module + '/'}
        element={
          <SecureRoute
            componentName="dashboard"
            Component={<Navigate to={`/${module}/dashboard`} />}
          />
        }
      />
      <Route
        path={module + '/dashboard'}
        element={
          <SecureRoute componentName="dashboard" Component={<Dashboard />} />
        }
      />
      <Route
        path={module + '/search'}
        element={
          <SecureRoute
            componentName="search"
            Component={
              module?.toLowerCase() !== 'admin' ? <Search /> : <Error />
            }
          />
        }
      />
      <Route
        path={module + '/entity/:entityId/account/create'}
        element={
          <SecureRoute
            componentName="createAccount"
            Component={<CreateAccount />}
          />
        }
      />
      <Route
        path={module + '/account/:accountId'}
        element={
          <SecureRoute
            componentName="maintainAccount"
            Component={<MaintainAccount />}
          />
        }
      />
      <Route
        path={module + '/entity/:entityId/account/:accountId'}
        element={
          <SecureRoute
            componentName="maintainAccount"
            Component={<MaintainAccount />}
          />
        }
      />
      <Route
        path={
          module +
          '/entity/:entityId/account/:accountId/bankaccount/:bankaccountId'
        }
        element={
          <SecureRoute
            componentName="bankAccountDetails"
            Component={<BankAccountDetails />}
          />
        }
      />
      <Route
        path={module + '/refundBatchDetails/:refundBatchId'}
        element={
          <SecureRoute
            componentName="refundBatchDetails"
            Component={<RefundBatchDetails />}
          />
        }
      />
      <Route
        path={module + '/refundBatches'}
        element={
          <SecureRoute
            componentName="refundBatchSummary"
            Component={<RefundBatchSummary />}
          />
        }
      />
      <Route
        path={'/property/entity/:entityId/account/:accountId/period/:periodId'}
        element={
          <SecureRoute
            componentName="accountPeriod"
            Component={<AccountPeriod />}
          />
        }
      />
      <Route
        path={'/revenue/entity/:entityId/account/:accountId/period/:periodId'}
        element={
          <SecureRoute
            componentName="accountPeriod"
            Component={<AccountPeriod />}
          />
        }
      />
      <Route
        path={module + '/asset/create'}
        element={
          <SecureRoute
            componentName="createAsset"
            Component={<CreateAsset />}
          />
        }
      />
      <Route
        path={module + '/entity/create'}
        element={
          <SecureRoute
            componentName="createEntity"
            Component={<CreateEntity />}
          />
        }
      />
      <Route
        path={module + '/entity/:entityId'}
        element={
          <SecureRoute
            Component={<MaintainEntity />}
            componentName="maintainEntity"
          />
        }
      />
      <Route
        path={module + '/asset/:assetId'}
        element={
          <SecureRoute
            componentName="maintainAsset"
            Component={<MaintainAsset />}
          />
        }
      />
      <Route
        path={'/property/entity/property/view/:type/:id'}
        element={
          <SecureRoute
            Component={<PropertyView />}
            componentName="proppertyView"
          />
        }
      />
      <Route
        path={'/property/letterDetails/:letterId/:templateId'}
        element={
          <SecureRoute
            Component={<LetterDetails />}
            componentName="letterDetails"
          />
        }
      />
      <Route
        path={'/revenue/entity/property/view/:type/:id'}
        element={
          <SecureRoute
            Component={<PropertyView />}
            componentName="proppertyView"
          />
        }
      />
      <Route
        path={'/property/entity/taxingauthority/view/:id'}
        element={
          <SecureRoute
            Component={<TaxingAuthorityView />}
            componentName="taxingAuthorityView"
          />
        }
      />
      <Route
        path={'/revenue/entity/taxingauthority/view/:id'}
        element={
          <SecureRoute
            Component={<TaxingAuthorityView />}
            componentName="taxingAuthorityView"
          />
        }
      />
      <Route
        path={module + '/viewfinancialtransgroup/:id'}
        element={
          <SecureRoute
            Component={<ViewFinancialTransGroup />}
            componentName="viewFinancialTransGroup"
          />
        }
      />
      <Route
        path={module + '/paymentdetails/:id'}
        element={
          <SecureRoute
            Component={<PaymentDetails />}
            componentName="paymentDetails"
          />
        }
      />
      <Route
        path={module + '/submissiondetails/:id'}
        element={
          <SecureRoute
            Component={<SubmissionDetails />}
            componentName="paymentDetails"
          />
        }
      />
      <Route
        path={'/property/billdetails/:id'}
        element={
          <SecureRoute
            Component={<BillDetails />}
            componentName="billDetails"
          />
        }
      />
      <Route
        path={'/revenue/billdetails/:id'}
        element={
          <SecureRoute
            Component={<BillDetails />}
            componentName="billDetails"
          />
        }
      />
      <Route
        path={'/property/entity/taxingauthority'}
        element={
          <SecureRoute
            Component={<ManageTaxingAuthority />}
            componentName="manageTaxingAuthority"
          />
        }
      />
      <Route
        path={'/revenue/entity/taxingauthority'}
        element={
          <SecureRoute
            Component={<ManageTaxingAuthority />}
            componentName="manageTaxingAuthority"
          />
        }
      />
      <Route
        path={'/property/entity/taxingauthority/:id'}
        element={
          <SecureRoute
            Component={<ManageTaxingAuthority />}
            componentName="manageTaxingAuthority"
          />
        }
      />
      <Route
        path={'/revenue/entity/taxingauthority/:id'}
        element={
          <SecureRoute
            Component={<ManageTaxingAuthority />}
            componentName="manageTaxingAuthority"
          />
        }
      />
      <Route
        path={'/property/batch/:id'}
        element={
          <SecureRoute
            Component={<CreateBatch />}
            componentName="createBatch"
          />
        }
      />
      <Route
        path={'/revenue/batch/:id'}
        element={
          <SecureRoute
            Component={<CreateBatch />}
            componentName="createBatch"
          />
        }
      />
      <Route
        path={'/property/batch'}
        element={
          <SecureRoute Component={<CreateBatch />} componentName="batch" />
        }
      />
      <Route
        path={'/revenue/batch'}
        element={
          <SecureRoute Component={<CreateBatch />} componentName="batch" />
        }
      />
      <Route
        path={'/property/managebatches'}
        element={
          <SecureRoute
            Component={<ManageBatchesProperty />}
            componentName="manageBatchesProperty"
          />
        }
      />
      <Route
        path={'/revenue/managebatches'}
        element={
          <SecureRoute
            Component={<ManageBatchesRevenue />}
            componentName="manageBatchesRevenue"
          />
        }
      />
      <Route
        path={'/property/managedeposits'}
        element={
          <SecureRoute
            Component={<ManageDepositsProperty />}
            componentName="manageDepositsProperty"
          />
        }
      />
      <Route
        path={'/revenue/managedeposits'}
        element={
          <SecureRoute
            Component={<ManageDepositsRevenue />}
            componentName="manageDepositsRevenue"
          />
        }
      />
      <Route
        path={'/property/deposit'}
        element={
          <SecureRoute
            Component={<CreateDeposit />}
            componentName="createDeposit"
          />
        }
      />
      <Route
        path={'/revenue/deposit'}
        element={
          <SecureRoute
            Component={<CreateDeposit />}
            componentName="createDeposit"
          />
        }
      />
      <Route
        path={'/property/batchDetails/:id'}
        element={
          <SecureRoute
            Component={<BatchDetails />}
            componentName="batchDetails"
          />
        }
      />
      <Route
        path={'/revenue/batchDetails/:id'}
        element={
          <SecureRoute
            Component={<BatchDetails />}
            componentName="batchDetails"
          />
        }
      />
      <Route
        path={'/property/depositDetails/:id'}
        element={
          <SecureRoute
            Component={<DepositDetails />}
            componentName="depositDetails"
          />
        }
      />
      <Route
        path={'/revenue/depositDetails/:id'}
        element={
          <SecureRoute
            Component={<DepositDetails />}
            componentName="depositDetails"
          />
        }
      />
      <Route
        path={module + '/financialTransaction/:id'}
        element={
          <SecureRoute
            Component={<FinancialTransaction />}
            componentName="financialTransaction"
          />
        }
      />
      <Route
        path={module + '/workflows'}
        element={
          <SecureRoute Component={<Workflows />} componentName="workflows" />
        }
      />
      <Route
        path={module + '/workflow/create/:variantId'}
        element={
          <SecureRoute
            Component={<CreateWorkflow />}
            componentName="createWorkflow"
          />
        }
      />
      <Route
        path={module + '/viewapprovalspage'}
        element={
          <SecureRoute
            componentName="approvalsPage"
            Component={<ApprovalsPage />}
          />
        }
      />
      <Route
        path={module + '/workflow/update/:key/:variantId'}
        element={
          <SecureRoute
            Component={<UpdateWorkflow />}
            componentName="updateWorkflow"
          />
        }
      />
      <Route
        path={`${module}/bulkUserUpload/`}
        element={
          <SecureRoute
            Component={<BulkUserUpload />}
            componentName="bulkUserUpload"
          />
        }
      />
      <Route
        path={`${module}/addUser/`}
        element={
          <SecureRoute Component={<AddUser />} componentName="addUser" />
        }
      />

      <Route
        path={`${module}/manageBusinessPolicies`}
        element={
          <SecureRoute
            Component={<BusinessPolicies />}
            componentName="manageBusinessPolicies"
            actionName="view"
          />
        }
      />

      <Route
        path={`${module}/manageRoles`}
        element={
          <SecureRoute
            Component={<Roles />}
            componentName="manageRoles"
            actionName="view"
          />
        }
      />

      <Route
        path={`${module}/manageroles/create`}
        element={
          <SecureRoute
            Component={<ManageRoles />}
            componentName="createManageRoles"
          />
        }
      />
      <Route
        path={`${module}/manageroles/edit/:roleId`}
        element={
          <SecureRoute
            Component={<ManageRoles />}
            componentName="manageRolesEditRole"
          />
        }
      />
      <Route
        path={`${module}/manageroles/clone/:roleId`}
        element={
          <SecureRoute
            Component={<ManageRoles />}
            componentName="manageRolesCloneRole"
          />
        }
      />
      <Route
        path={`${module}/manageuserroles`}
        element={
          <SecureRoute
            Component={<ManageUserRoles />}
            componentName="manageUserRoles"
          />
        }
      />
      <Route
        path={`${module}/manageuserroles/edit/:userId`}
        element={
          <SecureRoute
            Component={<EditUserRoles />}
            componentName="manageUserRoles"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/manageroles/edit/addUsers/:roleId`}
        element={
          <SecureRoute Component={<AddUsers />} componentName="addUsers" />
        }
      />
      <Route
        path={'/platform/managePolicyGroups'}
        element={
          <SecureRoute
            Component={<ManagePolicyGroups />}
            componentName="managePolicyGroups"
            actionName="view"
          />
        }
      />
      <Route
        path={'/platform/managepolicygroups/create'}
        element={
          <SecureRoute
            Component={<PolicyGroup />}
            componentName="createPolicyGroup"
          />
        }
      />

      <Route
        path={`${module}/manageBusinessPolicies/:action`}
        element={
          <SecureRoute
            Component={<BusinessPolicy />}
            componentName="createBusinessPolicy"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/manageBusinessPolicies/:action/:customerBusinessPolicyId`}
        element={
          <SecureRoute
            Component={<BusinessPolicy />}
            componentName="editBusinessPolicy"
            actionName="edit"
          />
        }
      />
      <Route
        path={'/platform/managepolicygroups/:action/:policeGroupId'}
        element={
          <SecureRoute
            Component={<PolicyGroup />}
            componentName="policyGroup"
          />
        }
      />
      <Route
        path={'/platform/tenant/view/:id'}
        element={
          <SecureRoute Component={<Tenant />} componentName="viewTenant" />
        }
      />
      <Route
        path={'/platform/tenantconfig'}
        element={
          <SecureRoute
            Component={<TenantConfig />}
            componentName="createTenant"
            actionName="view"
          />
        }
      />

      <Route
        path={'/platform/tenantconfig/:tenantId'}
        element={
          <SecureRoute
            Component={<TenantConfig />}
            componentName="tenantConfig"
            actionName="view"
          />
        }
      />
      <Route
        path={'/platform/tenantlist'}
        element={
          <SecureRoute
            Component={<TenantList />}
            componentName="tenantList"
            actionName="view"
          />
        }
      />
      <Route
        path={'/platform/customerlist'}
        element={
          <SecureRoute
            Component={<CustomerList />}
            componentName="customerList"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/printManagement`}
        element={
          <SecureRoute
            Component={<PrintManagementPage />}
            componentName="printManagement"
          />
        }
      />
      <Route
        path={'/platform/batchHistory'}
        element={
          <SecureRoute
            Component={<PrintManagementPage />}
            componentName="batchHistory"
          />
        }
      />
      <Route
        path={`${module}/activeDictionary`}
        element={
          <SecureRoute
            Component={<ActiveDictionary isFromTemplate={true} />}
            componentName="activeDictionary"
          />
        }
      />
      <Route
        path={`${module}/editDictionary/:dictionaryId`}
        element={
          <SecureRoute
            Component={<EditDictionary isFromTemplate={true} />}
            componentName="ActiveDictionary"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/manageforms/`}
        element={
          <SecureRoute
            Component={<ManageForms />}
            componentName="manageForms"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/manageforms/:formCategory/create`}
        element={
          <SecureRoute
            Component={<CreateForm />}
            componentName="manageForms"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/manageforms/edit/:formId`}
        element={
          <SecureRoute
            Component={<EditForm />}
            componentName="manageForms"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/forms/:formId/:version/fill`}
        element={
          <SecureRoute
            Component={<FillForm />}
            componentName="formEntry"
            actionName="edit"
          />
        }
      />
      <Route
        path={'/revenue/forms/:formId/:version/fill'}
        element={
          <SecureRoute
            Component={<FillForm />}
            componentName="formEntry"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/forms/suspense/:formDataId`}
        element={
          <SecureRoute
            Component={<FormSuspense />}
            componentName="formSuspense"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/channelDetails/:channelId/:channelName/:currentModule`}
        element={
          <SecureRoute
            Component={<ChannelDetailsPage />}
            componentName="channelDetails"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/scheduledTaskStagesDetails/:taskId/:taskName/:taskVersion/:taskRunHistoryId/:currentModule`}
        element={
          <SecureRoute
            Component={<StagesDetailsPage />}
            componentName="stagesDetails"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/scheduledTaskDetails/:taskId/:taskName/:currentModule`}
        element={
          <SecureRoute
            Component={<TaskDetailsPage />}
            componentName="taskDetails"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/managechannels/`}
        element={
          <SecureRoute
            Component={<ManageChannel />}
            componentName="manageChannels"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/managescheduledtasks/`}
        element={
          <SecureRoute
            Component={<ManageScheduledTasks />}
            componentName="manageScheduledTasks"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/managescheduledtasks/:action`}
        element={
          <SecureRoute
            Component={<ScheduledTasks />}
            componentName="ScheduledTasks"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/managescheduledtasks/:action/:TaskId/:ScheduleTaskName/:currentModule`}
        element={
          <SecureRoute
            Component={<ScheduledTasks />}
            componentName="ScheduledTasks"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/managechannels/:action`}
        element={
          <SecureRoute
            Component={<CreateChannel />}
            componentName="createChannels"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/managechannels/:action/:channelId/:channelName/:currentModule`}
        element={
          <SecureRoute
            Component={<CreateChannel />}
            componentName="manageChannels"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/manageforms/:action`}
        element={
          <SecureRoute
            Component={<CreateForm />}
            componentName="manageForms"
            actionName="edit"
          />
        }
      />
      <Route
        path={`${module}/manageforms/:action/:formGroupId`}
        element={
          <SecureRoute
            Component={<CreateForm />}
            componentName="manageForms"
            actionName="edit"
          />
        }
      />
      <Route
        path={'/property/forms'}
        element={
          <SecureRoute
            Component={<FormEntry />}
            componentName="formEntry"
            actionName="view"
          />
        }
      />
      <Route
        path={'/revenue/forms'}
        element={
          <SecureRoute
            Component={<FormEntry />}
            componentName="formEntry"
            actionName="view"
          />
        }
      />
      <Route
        path={`/${module}/viewForms`}
        element={
          <SecureRoute
            Component={<ViewForms />}
            componentName="viewForms"
            actionName="view"
          />
        }
      />
      <Route
        path={`/${module}/formView/:formDataId`}
        element={
          <SecureRoute
            Component={<FormView />}
            componentName="formView"
            actionName="view"
          />
        }
      />
      <Route
        path={`${module}/configTypeEditor`}
        element={
          <SecureRoute
            Component={<ConfigurationTool />}
            componentName="configTypeEditor"
          />
        }
      />
      <Route
        path={`${module}/configRecordEditor`}
        element={
          <SecureRoute
            Component={<ConfigurationTool />}
            componentName="configRecordEditor"
          />
        }
      />
      <Route
        path={'/platform/workFlowEditor/'}
        element={
          <SecureRoute
            Component={<WorkflowEditor />}
            componentName="workflowEditor"
          />
        }
      />
      <Route
        path={`${module}/configGroupEditor/`}
        element={
          <SecureRoute
            Component={<ConfigGroupEditor />}
            componentName="configGroupEditor"
          />
        }
      />
      <Route
        path={`${module}/generateIdConfig/`}
        element={<SecureRoute Component={<ConfigureId />} policy="policy19" />}
      />
      <Route
        path={`${module}/correspondenceTypes/`}
        element={
          <SecureRoute
            Component={<CorrespondenceTypes />}
            componentName="correspondenceTypes"
          />
        }
      />
      <Route
        path={`${module}/correspondenceTypes/create`}
        element={
          <SecureRoute
            Component={<ManageCorrespondenceType />}
            componentName="manageTemplate"
          />
        }
      />
      <Route
        path={`${module}/correspondenceTypes/:action/:corespId`}
        element={
          <SecureRoute
            Component={<ManageCorrespondenceType />}
            componentName="manageTemplate"
          />
        }
      />
      <Route
        path={`${module}/reusableContents/`}
        element={
          <SecureRoute
            Component={<ReusableContent />}
            componentName="reusableContent"
          />
        }
      />
      <Route
        path={`${module}/reusableContents/create`}
        element={
          <SecureRoute
            Component={<ManageReusableContent />}
            componentName="manageReusableContent"
          />
        }
      />
      <Route
        path={`${module}/reusableContents/:action/:contentId`}
        element={
          <SecureRoute
            Component={<ManageReusableContent />}
            componentName="manageReusableContent"
            actionName="edit"
          />
        }
      />
      <Route
        path={'/platform/logixtestharness/'}
        element={
          <SecureRoute Component={<LogixTest />} componentName="logixTest" />
        }
      />
      <Route
        path={'/platform/datamapper/'}
        element={
          <SecureRoute
            Component={<DataMapperTest />}
            componentName="dataMapperTest"
          />
        }
      />
      <Route
        path={'/platform/datamapperv2/'}
        element={
          <SecureRoute
            Component={<DataMapperTestV2 />}
            componentName="dataMapperTestV2"
          />
        }
      />
      <Route
        path={`${module}/sinkobjects/`}
        element={
          <SecureRoute
            Component={<SinkObjects />}
            componentName="sinkObjects"
          />
        }
      />
      <Route
        path={module + '/bookmarks'}
        element={
          <SecureRoute
            Component={<ManageFavorites />}
            componentName="bookmarks"
          />
        }
      />
      <Route
        path={module + '/navigation'}
        element={
          <SecureRoute Component={<Navigation />} componentName="navigation" />
        }
      />
      <Route
        path={'/welcome'}
        element={
          <SecureRoute
            Component={<Navigate to={`/${module}/dashboard`} />}
            componentName="welcome"
          />
        }
      />
      <Route
        path={module + '/settings'}
        element={
          <SecureRoute Component={<Settings />} componentName="settings" />
        }
      />
      <Route
        path={module + '/layoutbuilder'}
        element={
          <SecureRoute
            Component={<LayoutBuilderSandbox />}
            componentName="LayoutBuilderSandbox"
          />
        }
      />
      <Route
        path={module + '/gettingstarted'}
        element={
          <SecureRoute
            Component={<UnderConstruction />}
            componentName="gettingstarted"
          />
        }
      />
      <Route
        path={module + '/documentation'}
        element={
          <SecureRoute
            Component={<UnderConstruction />}
            componentName="documentation"
          />
        }
      />
      <Route
        path={module + '/training'}
        element={
          <SecureRoute
            Component={<UnderConstruction />}
            componentName="training"
          />
        }
      />
      <Route
        path={module + '/feedback'}
        element={
          <SecureRoute
            Component={<UnderConstruction />}
            componentName="feedback"
          />
        }
      />
      <Route
        path={module + '/reports'}
        element={
          <SecureRoute
            Component={<Reports />}
            componentName="reportsManagement"
          />
        }
      />
      <Route
        path={
          module +
          '/reports/:reportId/:typeMode/:templateId/:nameT/:descriptionT'
        }
        element={
          <SecureRoute Component={<PowerBi />} componentName="powerBi" />
        }
      />
      <Route
        path={module + '/reports/:reportId'}
        element={
          <SecureRoute
            Component={<ReportsDetails />}
            componentName="reportsDetails"
          />
        }
      />
      <Route
        path={module + '/dashboards'}
        element={
          <SecureRoute
            Component={<Dashboards />}
            componentName="dashboardsManager"
          />
        }
      />
      <Route
        path={module + '/dashboards/view/:dashboardId/'}
        element={
          <SecureRoute
            Component={<PowerBiDashboards />}
            componentName="powerBiDashboards"
          />
        }
      />
      <Route
        path={module + '/adjustmentdetail/:adjustmentId'}
        element={
          <SecureRoute Component={<Adjustment />} componentName="adjustment" />
        }
      />
      <Route
        path={'*'}
        element={<SecureRoute Component={<Error />} componentName="feedback" />}
      />
    </Routes>
  );
}

export default AppRoutes;
