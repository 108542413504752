import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  Button,
  MessageActionType,
  MessageType,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { useAppDispatch } from 'redux/hooks';
import {
  GetPaymentAdjustmentTypesQuery,
  PaymentDetailsInput,
  PaymentDetailsResponse,
  PeriodDetailsInput,
  RemittanceDetail,
  RemittanceDetailInput,
  UpdateSubmissionInput,
  useGetPaymentAdjustmentTypesQuery,
  useUpdateSubmissionMutation,
} from 'generated/graphql';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { addMessage } from 'redux/messageSlice';
import extractMeaningfulMessage from 'utils/errorMessage';
import { useParams } from 'react-router-dom';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import AdjustRemittancePreview from 'components/AdjustRemittancePreview';
import { useQueryClient } from '@tanstack/react-query';
import ControlledTextArea from 'components/controls/ControlledTextArea';
import EditPaymentsDataTable from 'components/EditPaymentsDataTable/EditPaymentsDataTable';
import EditRemittanceDataTable from 'components/EditRemittanceDataTable';
interface Props {
  remittances: RemittanceDetail[];
  payments: PaymentDetailsResponse[];
}

function AdjustRemittance({ remittances, payments }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const { id } = useParams() as { id: string };
  const [adjustmentReasons, setAdjustmentReasons] = useState<SelectType[]>([]);
  const [newRemittances, setNewRemittances] =
    useState<RemittanceDetailInput[]>();
  const [newPayments, setNewPayments] = useState<PaymentDetailsInput[]>([]);
  const [currentRowEdit, setCurrentRowEdit] = useState<string>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showNote, setShowNote] = useState<boolean>(false);

  const { control, formState, trigger, getValues, setValue } = useForm({
    mode: 'all',
  });
  const { isValid } = formState;

  const { mutate, isLoading } = useUpdateSubmissionMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['GetSubmissionDetails'],
      });
      setOpen(false);
      setShowPreview(false);
      dispatch(
        addMessage({
          message: t('pages.submissionDetails.adjustmentSuccess'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
    },
    onError: (error) => {
      const message = extractMeaningfulMessage(
        error,
        t('pages.periodDetails.transactions.manualChargeError')
      );
      dispatch(
        addMessage({
          message: message,
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    },
  });

  const { data: paymentAdjustmentTypes, error: paymentAdjustmentTypesError } =
    useGetPaymentAdjustmentTypesQuery();

  const buildAdjustmentTypesList = (
    options: GetPaymentAdjustmentTypesQuery | undefined
  ) => {
    const typeList: SelectType[] = [];
    options?.GetPaymentAdjustmentTypes.map((type) => {
      typeList?.push({
        key: type.name ? type.name : '',
        desc: type.description ?? '',
      });
    });
    return typeList;
  };

  const buildAdjustmentReasons = useCallback(
    (selectedAdjustmentType: string) => {
      const reasons = paymentAdjustmentTypes?.GetPaymentAdjustmentTypes.find(
        (adjustmentType) => adjustmentType.name === selectedAdjustmentType
      )?.reasons;

      const typeList: SelectType[] = [];
      reasons?.map((item) => {
        typeList?.push({
          key: item.name ? item.name : '',
          desc: item.description ?? '',
        });
      });
      setAdjustmentReasons(typeList);
    },
    [paymentAdjustmentTypes?.GetPaymentAdjustmentTypes]
  );

  useEffect(() => {
    if (paymentAdjustmentTypesError) {
      dispatch(
        addMessage({
          message: t('pages.submissionDetails.adjustmentTypesError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, t, paymentAdjustmentTypesError]);

  useEffect(() => {
    if (showPreview) {
      setCurrentRowEdit('');
    }
  }, [showPreview]);

  const resetRemittanceAmounts = () => {
    remittances.forEach((item) => {
      setValue(item.id ?? '', item.amount);
    });
  };

  const resetPaymentAmounts = () => {
    payments.forEach((item) => {
      setValue(item.id ?? '', item.amount);
    });
  };

  const closeModal = () => {
    setOpen(false);
    setShowPreview(false);
    resetPaymentAmounts();
    resetRemittanceAmounts();
  };

  const openModal = () => {
    setOpen(true);
  };

  const handleEditRow = (rowId: string) => {
    setCurrentRowEdit(rowId);
  };

  const handleSaveRow = () => {
    setCurrentRowEdit('');
  };

  const handlePaymentCancel = () => {
    setCurrentRowEdit('');
    resetPaymentAmounts();
  };

  const handleRemittanceCancel = () => {
    setCurrentRowEdit('');
    resetRemittanceAmounts();
  };

  const displayPreview = () => {
    if (isValid) setShowPreview(true);
  };

  const getIsNoteRequired = (
    adjustmentType: string,
    adjustmentReason: string
  ) => {
    return paymentAdjustmentTypes?.GetPaymentAdjustmentTypes.filter(
      (type) => type.name === adjustmentType
    )[0].reasons?.filter((reason) => reason.name === adjustmentReason)[0]
      .isNoteRequired;
  };

  function buildRequiredError(fieldName: string) {
    return (
      fieldName + ' ' + `${t('pages.periodDetails.transactions.required')}`
    );
  }

  useEffect(() => {
    if (remittances) {
      const newRemit: RemittanceDetailInput[] = [];
      remittances.forEach((item) => {
        const remittance: RemittanceDetailInput = {
          remittanceId: item.id,
          correctedAmount: item.amount,
        };
        newRemit.push(remittance);
        setValue(remittance.remittanceId ?? '', remittance.correctedAmount);
      });
      setNewRemittances(newRemit);
    }
  }, [remittances, setValue]);

  useEffect(() => {
    if (payments) {
      const newPaymentsArr: PaymentDetailsInput[] = [];
      payments.forEach((item) => {
        const periodsArr: PeriodDetailsInput[] = item.periods
          ? item.periods.map((s) => ({
              financialAccountPeriodId: s.financialAccountPeriodId,
              correctedAmount: s.amount,
            }))
          : [];
        const payment: PaymentDetailsInput = {
          paymentId: item.id,
          periods: periodsArr,
        };
        periodsArr.forEach((period) => {
          setValue(
            `${period.financialAccountPeriodId as unknown as string}`,
            period.correctedAmount
          );
        });
        newPaymentsArr.push(payment);
        setValue(payment.paymentId ?? '', payment.periods[0].correctedAmount);
      });
      setNewPayments(newPaymentsArr);
    }
  }, [payments, setValue]);

  const adjustSubmissionBtn = () => {
    return (
      <Box sx={{ textAlign: 'right', mr: '8px', ml: '20px' }}>
        <Button
          id="adjust-submission-link"
          type="secondary"
          variant="text"
          onClick={openModal}
        >
          {t('pages.submissionDetails.adjust')}
        </Button>
      </Box>
    );
  };

  const modalInnerContent = showPreview ? (
    <AdjustRemittancePreview
      paymentsTable={
        <EditPaymentsDataTable
          payments={payments}
          currentRowEdit={currentRowEdit ?? ''}
          control={control}
          getValues={getValues}
          handleCancel={handlePaymentCancel}
          handleSaveRow={handleSaveRow}
          handleEditRow={handleEditRow}
          showPreview={showPreview}
        />
      }
      remittanceTable={
        <EditRemittanceDataTable
          remittances={remittances}
          currentRowEdit={currentRowEdit ?? ''}
          control={control}
          getValues={getValues}
          handleCancel={handleRemittanceCancel}
          handleSaveRow={handleSaveRow}
          handleEditRow={handleEditRow}
          newRemittances={newRemittances ?? []}
          showPreview={showPreview}
        />
      }
      getValues={getValues}
      showNote={showNote}
      handleClick={() => {
        setShowPreview(false);
      }}
    />
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="paymentAdjustmentType"
            rules={{ required: true }}
            render={({
              field: { value, onChange },
              fieldState: { error: adjustmentTypeError },
            }) => {
              return (
                <Select
                  required
                  autowidth={false}
                  options={buildAdjustmentTypesList(paymentAdjustmentTypes)}
                  id="paymentAdjustmentType"
                  data-testid="paymentAdjustmentType"
                  label={t('pages.submissionDetails.paymentAdjustmentType')}
                  onChange={(e) => {
                    const selectedAdjustmentType = e.target.value as string;
                    setValue('paymentAdjustmentReason', undefined);
                    setShowNote(false);
                    buildAdjustmentReasons(selectedAdjustmentType);
                    onChange(e);
                  }}
                  fullWidth
                  value={value}
                  error={
                    adjustmentTypeError
                      ? buildRequiredError(
                          t('pages.submissionDetails.adjustmentType')
                        )
                      : ''
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="paymentAdjustmentReason"
            rules={{ required: true }}
            render={({
              field: { value, onChange },
              fieldState: { error: adjustmentReasonError },
            }) => {
              return (
                <Select
                  required
                  autowidth={false}
                  options={adjustmentReasons}
                  id="paymentAdjustmentReason"
                  data-testid="paymentAdjustmentReason"
                  label={t('pages.submissionDetails.paymentAdjustmentReason')}
                  onChange={(e) => {
                    const selectedAdjustmentReason = e.target.value as string;
                    if (
                      getValues('paymentAdjustmentType') &&
                      selectedAdjustmentReason
                    ) {
                      setShowNote(
                        getIsNoteRequired(
                          getValues('paymentAdjustmentType'),
                          selectedAdjustmentReason
                        ) ?? false
                      );
                    } else {
                      setShowNote(false);
                    }
                    onChange(e);
                  }}
                  fullWidth
                  value={value}
                  disabled={getValues('paymentAdjustmentType') === undefined}
                  error={
                    adjustmentReasonError
                      ? buildRequiredError(t('pages.submissionDetails.reason'))
                      : ''
                  }
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <EditPaymentsDataTable
        payments={payments}
        currentRowEdit={currentRowEdit ?? ''}
        control={control}
        getValues={getValues}
        handleCancel={handlePaymentCancel}
        handleSaveRow={handleSaveRow}
        handleEditRow={handleEditRow}
        showPreview={showPreview}
      />
      <EditRemittanceDataTable
        remittances={remittances}
        currentRowEdit={currentRowEdit ?? ''}
        control={control}
        getValues={getValues}
        handleCancel={handleRemittanceCancel}
        handleSaveRow={handleSaveRow}
        handleEditRow={handleEditRow}
        newRemittances={newRemittances ?? []}
        showPreview={showPreview}
      />
      {showNote && (
        <Grid item xs={12} mt={2}>
          <ControlledTextArea
            control={control}
            id="paymentAdjustmentNote"
            name="paymentAdjustmentNote"
            multiline={true}
            helperText={t('pages.submissionDetails.noteRequired')}
            label={t('pages.submissionDetails.note')}
            required
            rules={{ required: true }}
            sx={{ width: '100%' }}
          />
        </Grid>
      )}
    </>
  );

  const updateModel = () => {
    remittances.forEach((item, index) => {
      const updatedRemit = newRemittances;
      if (updatedRemit) {
        updatedRemit[index].correctedAmount = Number(getValues(`${item.id}`));
        setNewRemittances(updatedRemit);
      }
    });
    const updatedPayments = newPayments;
    updatedPayments.forEach((payment) => {
      payment.periods.forEach(
        (period) =>
          (period.correctedAmount = Number(
            getValues(`${period.financialAccountPeriodId}`)
          ))
      );
    });
  };

  function submit() {
    trigger()
      .then(updateModel)
      .then(displayPreview)
      .then(() => {
        if (isValid && showPreview) {
          const payload: UpdateSubmissionInput = {
            paymentAdjustmentType: getValues('paymentAdjustmentType'),
            paymentAdjustmentReason: getValues('paymentAdjustmentReason'),
            payments: newPayments,
            remittances: newRemittances,
            note: showNote ? getValues('paymentAdjustmentNote') : null,
          };
          mutate({ submission: payload, submissionId: id });
        }
      });
  }

  return (
    <>
      {adjustSubmissionBtn()}
      <Dialog
        id="adjustRemittancesDialog"
        open={open}
        children={modalInnerContent}
        title={t('pages.submissionDetails.adjustRemittances')}
        type="transactional"
        transactionModalTransactionButtonText={t(
          'pages.submissionDetails.adjust'
        )}
        handleClose={closeModal}
        handleCancelClick={closeModal}
        handleTransactionClick={submit}
        loading={isLoading}
      />
    </>
  );
}
export default AdjustRemittance;
