const en = {
  pages: {
    welcome: {
      title: 'Welcome',
      message: 'Welcome to Property Premier',
      logoutInacivity: 'You have been signed out due to inactivity',
      agreement: 'I agree to the',
      terms: 'Terms of System Use',
      signIn: 'Sign In',
      revXAppLogin: 'RevX App Login',
      warningOne:
        'Browsing is strictly prohibited! You may not access a taxpayers information unless the taxpayer is assigned to you or is otherwise directly related to your division of taxation duties. Unauthorized inspection or disclosure of information is prohibited by law.',
      warningTwo:
        'This system contains U.S. Government information. By accessing and using this computer system you are consenting to system monitoring for law enforcement and other purposes. Unauthorized use of, or access to, this computer system may subject you to state and federal criminal prosecution and penalties, as well as civil penalties.',
    },
    dashboard: {
      title: 'Dashboard',
      home: 'Home',
      workTab: 'My Work',
      managerTab: 'Work Management',
      getWork: 'Get Work',
    },
    navigation: {
      title: 'Navigation',
      layoutBuilder: 'Layout Builder Sandbox',
      entityManagement: 'Entity Management',
      batches: 'Batches',
      deposits: 'Deposits',
      forms: 'Forms',
      docs: 'Docs',
      tenants: 'Tenants',
      policyGroups: 'Policy Groups',
      businessPolicies: 'Business Policies',
      workflows: 'Workflows',
      channel: 'Channel',
      scheduledTasks: 'Scheduled Tasks',
      dataMapper: 'Data Mapper',
      roles: 'Roles',
      rolesAndPolicies: 'Roles and Policies',
      correspondence: 'Correspondence',
      logix: 'logiX',
      users: 'Users',
      configuration: 'Configuration',
    },
    header: {
      gettingStarted: 'Getting started',
      documentation: 'Documentation',
      training: 'Training',
      feedback: 'Feedback',
      onlineServices: 'Online Services',
      chatBot: 'Chat Bot',
      signOut: 'Sign Out',
    },
    footer: {
      loggedIn: 'Logged in as: ',
    },
    pageTitle: {
      title: 'PageTitle',
    },
    settings: {
      title: 'User Settings',
      selectModule: 'Select Preferred Module:',
      messages: {
        success: 'Saved User Preferrence',
        failure: 'Failed to save User Preferrence',
      },
    },
    appMenuBar: {
      favorites: 'Favorites',
      modules: 'Modules',
      searchPlaceHolder: 'Search',
      search: 'Search',
    },
    configuration: {
      title: 'User Config',
      lightOption: 'Light',
      darkOption: 'Dark',
      langEnglish: 'English',
      langPigLatin: 'Pig Latin',
      langEspanol: 'Español',
      language: 'Language',
    },
    layout: {
      skipToMain: 'Skip to Main Content',
      create: 'Create Layout',
      jsonMsg: 'Enter JSON here to test Layout Builder',
    },
    common: {
      requiredId: 'ID is required',
      requiredIdMsg: 'Please provide a valid ',
    },
    adjustmentDetails: {
      title: 'Adjustment Details',
      adjustmentReason: 'Adjustment Reason',
      reductionType: 'Reduction Type',
      adjustmentType: 'Adjustment Type',
      adjustmentTotal: 'Adjustment Total',
      impactView: 'Impact View',
      transactionView: 'Transaction View',
      percent: 'Percent',
      approve: 'Approve',
      adjustmentApproved: 'Adjustment Approved',
      adjustmentApprovedError: 'Error Approving Adjustment',
      adjustmentRejected: 'Adjustment Rejected',
      adjustmentRejectedError: 'Error Rejecting Adjustment',
      reject: 'Reject',
    },
    manageTaxingAuthority: {
      title: 'Manage Taxing Authority',
      taxAuthorityDetails: 'Tax Authority Details',
      taxingAuthorityId: 'Taxing Authority Id',
      name: 'Name',
      description: 'Description',
      taxYear: 'Tax Year',
      fiscalBeginDate: 'Fiscal Begin Date',
      fiscalEndDate: 'Fiscal End Date',
      taxRates: 'Tax Rates',
      taxRate: 'Tax Rate',
      rate: 'Rate',
      taxType: 'Tax Type',
      effectiveBeginDate: 'Effective Begin Date',
      errorExists: 'ID already exists',
      effectiveEndDate: 'Effective End Date',
      exemptions: 'Exemptions',
      exemption: 'Exemption',
      exemptionType: 'Exemption Type',
      amount: 'Amount',
      maxAmount: 'Max Amount',
      percentage: 'Percentage',
      totalTaxRate: 'Total Tax Rate',
      addresses: 'Addresses',
      address: 'Address',
      emails: 'Emails',
      email: 'Email',
      phoneNumbers: 'Phone Numbers',
      phone: 'Phone',
      viewTaxingAuthority: 'View Taxing Authority',
      details: 'Details',
      addExemption: 'Add Exemption',
      addTaxRate: 'Add Tax Rate',
      createTaxingAuthority: 'Create Taxing Authority',
      taxRatesExemptions: 'Tax Rates and Exemptions',
      taxPeriod: 'Tax Period',
      addYear: 'Add Year',
      taxRatesUpdated: 'Tax Rates Updated',
      taxingAuthorityCreated: 'Taxing Authority Created',
      taxingAuthorityType: 'Taxing Authority Type',
      taxAreas: 'Tax Areas',
      add: 'Add',
      contact: {
        invalidEmail: 'Invalid Email Address',
        invalidPhone: 'Invalid Phone Number',
      },
      validation: {
        invalidNumber: 'Invalid number',
        invalidDecimal: 'Invalid decimal number',
      },
    },
    horizontalNonLinearStepper: {
      allStepsCompleted: 'All steps completed - you are finished',
      step: 'Step',
      alreadyCompleted: 'already completed',
      save: 'Save',
      nextStep: 'Next Step',
      prevStep: 'Previous Step',
    },
    adminTools: {
      title: 'Admin Tools',
    },
    manageRole: {
      title: 'Manage Role',
      assignedPolicyGroups: 'Assigned Policy Groups',
      assignedUsers: 'Assigned Users',
      createRole: 'Create Role',
      editRole: 'Edit Role',
      cloneRole: 'Clone Role',
      module: 'Platform or Module',
      navTitle: 'Manage Role',
      platformPolicies: 'Platform Policies',
      policyGroupName: 'Policy Group Name',
      policyGroupDescription: 'Policy Group Description',
      policyGroupTechnicalName: 'Technical Name',
      roleName: 'Role Name',
      roleDescription: 'Description',
      enabled: 'Unlocked',
      disabled: 'Locked',
      roleExistsMessage: 'Role name already exists',
      selected: 'Selected',
      SelectedPolicyGroups: 'Selected Policy Groups',
      selectAll: 'Select All',
      save: 'Save',
      updateRole: 'Update Role',
      saveAndCreate: 'Save and Create Another',
      successMessage: 'Role saved successfully.',
      cloneSuccessMessage: 'Role was cloned successfully.',
      userName: 'User Name',
      userEmail: 'Email',
      roleFieldError: 'At least 3 characters are required',
      roleFieldMaxLengthError: 'The maximum characters allowed are 245',
      roleNameFieldMaxLengthError: 'The maximum characters allowed are 100',
      noPolicyGroupsForSelectedModule:
        'There are no policy groups assigned to this module.',
      noUsersForSelectedModule: 'There are no users assigned to this module.',
      userWarning:
        'Warning: There are users currently assigned to this role. Any changes to this role may affect those users.',
    },
    businessPolicies: {
      navTitle: 'Manage Business Policies',
      title: 'Manage Business Policies',
      showDomainId: 'Show Business Policies for:',
      allDomains: 'All Domains',
      isErrorBusinessPoliciesList: 'Business Policies List is unavailable',
      domain: 'Domain',
      customerBusinessPolicyId: 'Customer Business Policy Id',
      name: 'Name',
      description: 'Description',
      assignedRoles: 'Assigned Roles',
      roles: ' Roles',
    },
    businessPolicy: {
      successMessage: 'Business Policy saved successfully.',
      previousPage: 'Manage Business Policies',
      customerBusinessPolicyId: 'Customer Business Policy Id',
      name: 'Name',
      description: 'Description',
      selectedRoles: 'Selected Roles',
      roleName: 'Role Name',
      roleDescription: 'Role Description',
      assignedRoles: 'Assigned Roles',
      systemPolicies: 'System Policies',
      errors: {
        customerBusinessPolicyId:
          'The correct format is BP-{Six Chars}-{Five Digits}',
        customerBusinessPolicyIdExists:
          'Customer BusinessPolicy Id already exists',
        businessPolicyNameExists: 'Business Policy Name already exists',
        maxChars: {
          name: 'The maximum characters allowed are 100',
          description: 'The maximum characters allowed are 245',
        },
        minChars: {
          name: 'The minimum characters allowed are 3',
          description: 'The minimum characters allowed are 3',
        },
      },
      dropdown: {
        domains: 'Domain',
        selectDomain: 'Select Domain',
      },
      create: {
        title: 'Create Business Policy',
        buttons: {
          create: 'Create New Business Policy',
          save: 'Save',
        },
        successMessage: 'Business Policy saved successfully.',
        errorMessage:
          'An error ocurred when trying to create a new Business Policy.',
      },
      edit: {
        title: 'Edit Business Policy',
        header: {
          name: 'Name',
          description: 'Description',
        },
        buttons: {
          update: 'Update',
        },
        successMessage: 'Business Policy updated successfully.',
        errorMessage:
          'An error ocurred when trying to update a new Business Policy.',
      },
    },
    roles: {
      title: 'Manage Roles',
      navTitle: 'Manage Roles',
      showRolesFor: 'Show roles for:',
      selectPlatformModule: 'Select platform/module',
      state: 'State',
      isOOTB: 'Is OOTB',
      name: 'Role Name',
      policyGroups: 'Policy Groups',
      users: 'Users',
      businessPolicies: 'Business Policies',
      platform: 'Platform',
      module: 'Module',
      status: 'Status',
      description: 'Role Description',
      technicalName: 'Technical Name',
      assignedPolicyGroups: 'Assigned Policy Groups',
      assignedBusinessPolicies: 'Assigned Business Policies',
      assignedPolicies: 'Assigned Policies',
      assignedUsers: 'Assigned Users',
      buttons: {
        create: 'Create new role',
        cancel: 'Cancel',
        accept: 'Accept',
      },
      dialog: {
        warning: {
          title: 'Warning',
          subtitle:
            'The following business policies will be assigned to this cloned role:',
          note: 'Click Accept to proceed to the Clone Role page',
        },
      },
      allModules: 'All Modules',
      permission: 'Letter Permission',
      errors: {
        modulesUnavailable: 'An error has occurred while loading Modules',
        rolesUnavailable: 'An error has occurred while loading Roles',
      },
    },

    managePolicyGroups: {
      title: 'Manage Policy Groups',
      name: 'Name',
      description: 'Description',
      allModules: 'All Modules',
      policies: 'Policies',
      platform: 'Platform',
      module: 'Module',
      assignedPolicies: 'Assigned Policies',
      showPolicyGroupsFor: 'Show policy groups for:',
      selectPlatformModule: 'Select platform/module',
      technicalName: 'Technical Name',
      isErrorPolicyGroupsByModule:
        'There are no policy groups assigned to this module',
    },
    policyGroup: {
      module: 'Module',
      assignedSystemPolicies: 'Assigned System Policies',
      policyName: 'Policy Name',
      policyDescription: 'Policy Description',
      navTitle: 'Manage Policy Groups',
      platformPolicies: 'Platform Policies',
      policyGroupName: 'Policy Group Name',
      policyGroupTechnicalName: 'Technical Name',
      policyGroupDescription: 'Policy Group Description',
      enabled: 'Unlocked',
      disabled: 'Locked',
      selected: 'Selected',
      selectedPolicies: 'Selected Policies',
      selectAll: 'Select All',
      save: 'Save',
      saveAndCreate: 'Save and Create Another',
      userWarning:
        'Warning: There are users currently assigned to this role. Any changes to this role may affect those users.',
    },
    policyGroupCreate: {
      successMessage: 'Policy Group saved successfully.',
      policyGroupExistsMessage: 'Policy Group name already exists',
      policyGroupFieldError: 'At least 3 characters are required',
      policyGroupFieldMaxLengthError: 'The maximum characters allowed are 245',
      noPoliciesForSelectedModule:
        'There are no policies assigned to this module.',
      previousPage: 'Manage Policy Group',
      title: 'Create Policy Group',
      buttons: {
        create: 'Create new policy group',
      },
      allModules: 'All Modules',
    },
    policyGroupEdit: {
      title: 'Edit Policy Group',
      updatePolicyGroup: 'Update Policy Group',
      module: 'Platform or Module',
      policyGroupName: 'Policy Group Name',
      policyGroupDescription: 'Policy Group Description',
      successMessage: 'Policy Group edited successfully.',
    },
    bulkUserUpload: {
      navTitle: 'Bulk Upload',
      title: 'Bulk User Upload',
      previewHeaderTitle: 'Bulk User Upload - Preview',
      summaryHeaderTitle: 'Bulk User Upload - Summary',
      cardTitle: 'How to upload',
      uploadFile: 'Upload',
      checkedFileText:
        'We’ve checked your file for errors and this is what we found.',
      continueText:
        'You can continue with warnings but errors need to be fixed in the Bulk User File template and uploaded again.',
      showAllResults: 'Show all results',
      commitWithErrors:
        'Users that contain warnings will be processed with incomplete data and users with errors will be skipped entirely',
      acceptFile: '.csv',
      result: {
        row: 'Row',
        status: 'Status',
        details: 'Details',
        data: 'Data',
        roleStartDate: 'Role Start Date',
        roleEndDate: 'Role End Date',
      },
      instructions: {
        title: 'How to upload:',
        download: '1. Download the',
        bulkFile: 'Bulk User File template',
        add: '2. Add your data to the template',
        steps: {
          dontChange: "a. Don't change the order of the columns.",
          enter: 'b. Enter one row per each role to be assigned to the user.',
          moduleName: 'c. Module Name and Role Name are optional fields.',
          moduleAndRoleName:
            'd. If the Module Name and/or Role Name are populated then the Module Name and/or Role Name must exist and be active in the system.',
          roleName: 'e. Role Name must be part of the Module Name specified.',
          roleStartandEnd:
            'f. Role Start Date and Role End Date are optional. If they are filled, the Role Start Date must be between today and the Role End Date',
          formatDates:
            'g. The format for the Role Start Date and Role End Date is MM/DD/YYYY.',
          datesAreNotProvided:
            'h. If the Role Start Date and/or Role End Date is not provided, then the system will assume the current date is the start date without expiration. That is, the current date will be defaulted to the Role Start Date and the Role End Date will not be populated to indicate that there is no end date for the role.',
          datesEquals:
            'i. If the Role Start Date and Role End Date are set to the same date, then the duration of the role will be just one day.',
        },
        example: {
          title: 'Example:',
          header:
            'User First Name, User Last Name, User Email, Module Name, Role Name, Role Start Date, Role End Date',
          correct: {
            row1: 'John, Doe, jdoe@company.com, Global Roles, RSIConsultant, 07/02/2022, 08/10/2022',
            row2: 'Jane, Doe, janed@company.com, , , ,	',
          },
          incorrect:
            'Joseo, Perez, jperez@company.com, Local Module, InvalidRole, 08/10/2022, 02/01/2021',
        },
        correct: 'Correct',
        incorrect: 'Incorrect',
        assistance:
          'If you need further assistance please contact revx@rsimail.com',
        upload: '3. Upload it below for processing',
      },
      boxes: {
        valids: 'Valids',
        warnings: 'Warnings',
        successes: 'Successes',
        skipped: 'Skipped',
        errors: 'Errors',
      },
      buttons: {
        upload: 'Upload',
        startOver: 'Start over',
        applyChanges: 'Apply changes',
      },
      uploadedFile: 'Your File has been successfully uploaded',
    },
    addUser: {
      title: 'Add User into Platform',
      navTitle: 'Add User',
      emailAddress: 'Email Address',
      requiredField: 'Requiered Field',
      firstName: 'First Name',
      lastName: 'Last Name',
      addUserButton: 'Add User',
      userAdded: 'User was added successfully',
      buttons: {
        assignRole: 'Assign Role',
        createAnotherNewUser: 'Create Another New User',
        cancel: 'Cancel',
      },
      errors: {
        email: 'Invalid Email Address',
        existingUser: 'Email Address is already in use',
        lastNameLength: 'The Last Name is greater than 150 characters',
        firstNameLength: 'The First Name is greater than 150 characters',
        emailLength: 'The email length is greater than 255 characters',
        fullNameLength:
          'The First Name and Last Name is greater than 150 characters',
        emailRequired: 'Please enter valid email',
        firstNameRequired: 'Please enter valid First Name',
        lastNameRequired: 'Please enter valid Last Name',
      },
    },
    customerList: {
      title: 'Customer List',
      navTitle: 'Customer List',
      fetchError: 'An error has occurred while loading the Customer List',
      isErrorCustomers: 'Customer List is unavailable',
      showCustomerListFor: 'Show Customer List For',
      name: 'Customer Name',
      customerId: 'Customer ID',
      contractId: 'Contract ID',
      modules: 'Module(s)',
    },
    tenantDialog: {
      title: 'Tenant Creation In Progress',
      description1:
        'The request to create a new tenant has been received successfully.',
      description2:
        'In the interval of 2 to 12 minutes we will notify you about the status of the creation of the Tenant.',
      description3: 'Please check your mail inbox.',
      buttons: {
        accept: 'Accept',
      },
    },
    tenantConfig: {
      title: 'Create Tenant',
      navTitle: 'Create Tenant',
      description:
        'Use this checklist to create a new tenant in this platform.',
      buttons: {
        saveDraft: 'Save As Draft',
        updateDraft: 'Update Draft',
        saveAndCreate: 'Save And Create Tenant',
        create: 'Create New Tenant',
        next: 'Next',
      },
      tenantInfo: {
        title: 'Customer & Tenancy Information',
        description:
          'Enter tenant, customer names and, at least, one primary contact. Step 1 and Step 2 must be completed to save as draft.',
        primaryTitle: 'Primary Contact Information',
        secondaryTitle: 'Secondary Contact Information',
        tenantName: 'Tenant Name',
        domainName: 'Domain Name',
        customerName: 'Customer Name',
        contacts: 'Contacts',

        primaryContact: {
          name: 'Name',
          phone: 'Phone',
          email: 'Email',
        },
        secondaryContact: {
          name: 'Name',
          phone: 'Phone',
          email: 'Email',
        },
      },
      rsiContacts: {
        title: 'RSI Contact(s)',
        description:
          'There must be at least one primary contact added. Any additional people added will be secondary contacts. Step 1 and Step 2 must be completed to save as draft.',
        availableContacts: 'Available Contacts',
        type: 'Type',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        organization: 'Organization',
      },
      tenancyConfiguration: {
        title: 'Tenancy Configuration',
        locale: 'Locale',
        language: 'Language',
        timeZone: 'Time Zone',
        currency: 'Currency',
        internalUrl: 'Internal Portal URL',
        externalUrl: 'External Portal URL',
        mgtAdminName: 'Management Admin Name',
        mgtAdminEmail: 'Management Admin Email',
        selectLocale: 'Select Locale',
        selectLanguage: 'Select Language',
      },
      tenantIdp: {
        title: 'Tenant Identity Provider',
        identityProvider: 'Identity Provider',
      },
      selectModules: {
        title: 'Modules',
        invalidMandatoryModule: 'Invalid Mandatory Module',
      },
      errors: {
        phone: 'Invalid Phone Number',
        email: 'Invalid Email Address',
        mgtAdminEmail: 'Invalid Admin Email',
        tenantName: 'Tenant name is already in use',
      },
    },
    userRolesList: {
      title: 'Manage User Roles',
      navTitle: 'Manage User Roles',
      users: 'Users',
      name: 'Name',
      email: 'Email',
      roles: 'Roles',
      edit: 'Edit',
      searchBy: 'search by user name or email..',
      roleName: 'Role',
      moduleName: 'Module',
      startDate: 'Start Date',
      endDate: 'End Date',
      noDateSpecified: 'No date specified',
      errorMsg: 'Please enter at least 3 characters to search',
    },
    tenantList: {
      title: 'Tenant Onboarding',
      navTitle: 'Tenant List',
      status: 'Status',
      tenantName: 'Tenant Name',
      serviceAdmin: 'Service Administrator',
      tenantContact: 'Tenant Contact',
      modules: 'Modules',
      messages: 'Messages',
      creationDate: 'Creation Date',
      lastModifiedDate: 'Updated Date',
      lastModifiedBy: ' Updated By',
      searchPlaceholder: 'search tenants',
      statusMessage: 'Status Message',
    },
    tenantView: {
      navTitle: 'View Tenant',
      tenant: 'Tenant',
      status: 'Status',
      editButton: 'Edit',
      tenantContacts: 'Tenant Contacts',
      rsiContacts: 'RSI Contacts',
      tenancyConfiguration: 'Tenancy Configuration',
      tenantIdP: 'Tenant Identity Provider',
      modulesTax: 'Modules',
      messageHistory: 'Message History',
      name: 'Name',
      userOrganization: 'Organization',
      phone: 'Phone',
      email: 'Email',
      locale: 'Locale',
      language: 'Language',
      timeZone: 'Time Zone',
      currency: 'Currency',
      revxDefaulDomains: 'revX Default Domain',
      onlineDefaulDomains: ' Online Services Default  Domain',
      noDefaultDomainsConfigured: 'No Default Domains configured',
      noRevxDefaultDomainsConfigured: 'No Revx Default Domains configured',
      noOnlineDefaultDomainsConfigured:
        'No Online Services Default Domains configured',
      customDomains: 'Custom Domains',
      noCustomDomainsConfigured: 'No Custom Domains configured',
      defaultIdentityProvider: 'Default Identity Provider',
      externalIdentityProvider: 'External Identity Provider',
      tenantIdentityProvider: 'Edit Tenant IdP',
      adminName: 'Management Admin Name',
      adminEmail: 'Management Admin Email',
      dateSent: 'Date Sent',
      resend: 'Resend',
      primaryContact: 'Primary Contact Information',
      secondaryContact: 'Secondary Contact Information',
      thirdContact: '3rd Party Contact Information',
      contactInformation: 'Contact Information',
      noIdpConfigured: 'No Tenant Identity Provider configured',
      modal: {
        title: 'Add Tenant Identity Provider Information',
        tenantId: 'Tenant Id',
        tenantProvider: 'Tenant OpenId Provider',
        tenantOpenIdMetadataUrl: 'OpenId MetadataUrl',
        clientId: 'Client Id',
        clientSecret: 'Client Secret',
        cancel: 'Cancel',
        apply: 'Apply',
      },
      errors: {
        tenantIdRequired: 'Please enter valid Tenant Id',
        tenantOpenIdMetadataUrlRequired:
          'Please enter valid Tenant OpenId MetadataUrl',
        clientIdRequired: 'Please enter valid Client Id',
        clientSecretRequired: 'Please enter valid Client Secret',
        invalidOpenIdMetadataUrl:
          'The value is not a valid OpenID metadata Url',
      },
      notifications: {
        success: 'Tenant Identity Provider successfully created',
        continue:
          'Do you want to save and update the Tenants Identity Provider information?',
        processing:
          'Creating / Updating Tenant Identity Provider, please wait...',
      },
    },
    manageForms: {
      title: 'Docs Design',
      name: 'Form Name',
      date: 'Creation Date',
      update: 'Last Updated',
      version: 'Version',
      newForm: 'New Form',
      editForm: 'Edit Form',
      manageList: 'Manage Form List',
      formDetails: 'Form Details',
      formStructure: 'Form Structure',
      fieldRequired: 'Field is required',
      formNameExists: 'Form Name Already Exists',
      success: 'Form saved successfully',
      layoutBuilder: 'Layout Builder',
      buttons: {
        create: 'Create new form',
        save: 'Save Form',
      },
      fillForm: 'Fill Form',
      identifier: 'Identifier',
      beginForm: 'Begin Form',
      batchId: 'Batch Id',
      receivedDate: 'Received Date',
      noFormsInFormTemplate:
        'Cannot update form layout, missing formTemplate data',
      saveSuccessLayout: 'Layout was successfully saved',
      updateSuccessLayout: 'Layout was successfully updated',
      layoutContext: 'Layout Context',
    },
    forms: {
      title: 'Docs',
      naveTitle: 'Form Entry',
      navTitleViewForms: 'View Forms',
      dataMapperTemp: 'Temporary button needed to save mappings',
      saveMapping: 'Save Mapping',
      numberEntries: 'Number of Entries',
      previousPage: 'Dashboard',
      error: 'You need to select a form to fill',
      noSpacesBatchId: 'Batch ID may not contain spaces',
      characterNotAllowed: 'Character not allowed',
      batchInUseClosed: 'Batch Id is already in use and closed',
      errorValidatingBatch: 'Error validating batchId is unique or open status',
      caution: 'Warning',
      continue: 'Continue',
      resumeBatch: 'Resume Batch',
      form: 'Form *',
      batchId:
        'You haven´t specified a Batch Id. A Batch Id will be generated automatically. Are you sure you want to continue?',
      receivedDate:
        'You haven´t specified a Received Date. Are you sure you want to continue?',
      emptyFields:
        'You haven´t specified Batch Id and Received Date. A Batch Id will be generated automatically. Are you sure you want to continue?',

      fields: 'Fields are empty',
      batchField: 'Batch Id',
      date: 'Received Date',
      buttons: {
        next: 'Next',
        warning: 'Are you sure you want to continue?',
        create: 'Create new form',
        save: 'Save Form',
      },
      docsDesign: 'Docs Design',
      formDesign: 'Forms Design',
      name: 'Form Name',
      creationDate: 'Creation Date',
      update: 'Last Updated',
      version: 'Version',
      newForm: 'New Form',
      editForm: 'Edit Form',
      manageList: 'Manage Form List',
      formDetails: 'Form Details',
      formStructure: 'Form Structure',
      fieldRequired: 'Field is required',
      formNameExists: 'Form Name Already Exists',
      success: 'Form saved successfully',
      fillForm: 'Fill Form',
      identifier: 'Identifier',
      beginForm: 'Begin Form',
      isActive: 'Is Active',
      edit: 'Edit',
      yes: 'Yes',
      no: 'No',
      formError: 'Form service is unavailable',
      showFormFor: 'Show Form For',
      editActiveStatus: 'Edit Active Status',
      filldataform: 'fill form data',
      cloneForm: 'clone form',
      editFormBtn: 'edit form',
      checkButton: 'check form',
      closeButton: 'close form',
      forWorkflow: 'For Workflow?',
      module: 'Module',
      autogenerateBatchIdError: "A new batch Id couldn't be created: ",
      viewForms: {
        title: 'Forms View',
        headerDln: 'DLN',
        headerName: 'Form Name',
        headerDate: 'Creation Date',
      },
      formView: {
        title: 'Form View',
        backTo: 'Forms View',
      },
      suspenseForm: 'Suspense Form',
    },
    dataMapper: {
      options: 'Sink Objects',
      srNumber: 'Sr Number',
      logixGroup: 'Logix Group',
      sinkObjects: 'Sink Objects',
      fieldName: 'Field Name',
      action: 'Action',
      context: 'Context',
    },
    dataMapperTestHarness: {
      navTitle: 'DataMapper Harness',
    },
    dataMapperTestHarnessV2: {
      navTitle: 'DataMapper V2',
      wip: 'WORK IN PROGRESS',
      changeInputSchema: 'Change Input Schema',
      buttonInfo:
        '*** This button is added for POC/Demo purpose only, Domains do not need to use it.',
    },
    sinkObjects: {
      navTitle: 'Sink Object Browser',
    },
    generateIdConfig: {
      add: 'Add',
      title: 'Generate ID Configuration',
      configDomain: 'Configuration Domain',
      configModule: 'Configuration Module',
      configType: 'Configuration Type',
      configEntity: 'Configuration Entity',
      configName: 'Configuration Name',
      configDescription: 'Configuration Description',
      uniqueWithin: 'Unique Within',
      fieldType: 'Input Type',
      fieldValue: 'Value',
      length: 'Length',
      sequentialWithin: 'Sequential within',
      isHexadecimal: 'Is Hexadecimal?',
      dateFormat: 'Date Format',
      save: 'Save ID Configuration',
      preview: 'Preview: ',
      instructions:
        'Add at least one input type below and additional inputs as needed. Up to 10 inputs can be included in a generated ID.',
      success: 'ID Configuration saved successfully.',
    },
    manageChannel: {
      title: 'Manage Channels',
      titleTask: 'Manage Scheduled Tasks',
      scheduledTasks: 'Scheduled Tasks',
      status: 'Status',
      state: 'State',
      version: 'Version',
      taskGroup: 'Task Group',
      updatedDttm: 'Updated Date',
      ScheduleTaskName: 'Scheduled Task Name',
      ScheduleTaskId: 'Scheduled Task Id',
      createTask: 'Create New Scheduled Task',
      newChannel: 'Create Channel',
      allModules: 'All Modules',
      allowDuplicateFiles: 'Allow Duplicate Files',
      publish: 'Publish',
      scheduleTaskName: 'Scheduled Task Name',
      stageTaskName: 'Stage Name',
      stageTaskDisplayName: 'Stage Display Name',
      stageTaskDescription: 'Stage Description',
      stageTaskExecutionOrder: 'Execution Order *',
      stageTaskNameExists: 'This stage name already exists',
      stageTaskOnFailure: 'Continue Task on Stage Failure',
      stageTaskAdd: 'Add Stage',
      stageTaskRemove: 'Remove Stage',
      executionOrderTooLow: 'The execution order must be 1 or greater',
      executionOrderExists: 'This execution order already exists',
      stageTaskRemoveWarning: 'Are you sure you want to delete this stage?',
      stageTaskRemoveFinalWarning:
        'Warning: If you delete this stage, you will not be able to recover it.',
      scheduleChannel: 'Add a schedule to the channel?',
      scheduleTask: 'Add a schedule to the scheduled task',
      noSchedule: 'No schedule needed',
      createSchedule: 'Create Schedule',
      last: 'Last Day Of Month',
      createdDate: 'Created Date',
      updatedDate: 'Updated Date',
      activeVersion: 'Active Version',
      nextRunTime: 'Next Run Time:',
      updateChannel: 'Update Channel',
      saveChannel: 'Save Channel',
      dataMapper: 'Data Mapper',
      runId: 'Run Id',
      channelVersion: 'Channel Version',
      channelStatus: 'Channel Status',
      totalRecords: 'Total Records',
      recordsSuccess: 'Records Success',
      recordsFailed: 'Records Failed',
      recordsProcessed: 'Records Processed',
      runStatus: 'Status',
      taskVersionValue: 'Task Version',
      taskStatus: 'Task Status',
      stageStatus: 'Stage Status',
      taskStagesExecutionOrder: 'Execution Order',
      initiatedBy: 'Initiated By',
      initiatedType: 'Initiated Type',
      startTime: 'Start Time',
      endTime: 'End Time',
      elapsedTime: 'Elapsed Time',
      name: 'Channel Name',
      id: 'Channel Id',
      channelDetails: 'Channel Details',
      taskDetails: 'Scheduled Tasks Details',
      displayName: 'Display Name',
      description: 'Description',
      fileGroupDetails: 'File Group Details',
      hasHeader: 'Has Header',
      delimiterValue: 'Delimiter Value',
      delimiter: 'Delimiter',
      fileDetails: 'File Details',
      scheduleExecutionTimes: 'Schedule execution times:',
      sourceSchemaDef: 'Source Schema Definition',
      fileGroupName: 'File Group Name',
      fileGroupType: 'File Group Type',
      channelNameExists: 'Channel Name Already Exists',
      taskNameExists: 'Schedule Task Name Already Exists',
      platform: 'Platform/Module',
      currentVersion: 'Current Version',
      currentStatus: 'Current Status',
      fileProcessingType: 'File Processing Type',
      fileProcessingOrder: 'File Processing Order',
      file: 'File',
      fileName: 'File Name',
      addFile: 'Add New File',
      createScheduledTask: 'Create Scheduled Tasks',
      manageTask: 'manage scheduled tasks',
      fileDescription: 'File Description',
      connector: 'Connector',
      logix: 'logiX',
      test: 'Test',
      endDateCheck: 'End date cannot be less than start date',
      isCompressed: 'Is Compressed',
      isEncrypted: 'Is Encrypted',
      isFti: 'Is FTI',
      parameters: 'Parameters',
      clone: 'Clone',
      delete: 'Delete',
      archive: 'Archive',
      channelScheduleName: 'Schedule Name',
      scheduleName: 'Schedule Name',
      addSchedule: 'Add New Schedule',
      runDays: 'Run on these days: ',
      monthDays: 'Select day(s) of the month to execute',
      scheduleTime: 'Schedule execution time',
      endDate: 'Specify an end date',
      newChannelSave: 'Create New Channel',
      executeTimes: 'Execute at these times:',
      hour: 'Hour',
      hourMask: 'Range 0-23',
      minuteMask: 'Range 0-59',
      minuteRange: 'Type Minute, interval range 1 - 720000 inclusive',
      hourRange: 'Type Hour, interval range 1 - 12000 inclusive',
      dayRange: 'Type Day, interval range 1 - 500 inclusive',
      weekRange: 'Type Week, interval range 1 - 71 inclusive',
      monthRange: 'Type Month, interval range 1 - 16 inclusive',
      minutes: 'Minutes',
      createChannel: 'Create Channel',
      hostName: 'Invalid Host Name',
      portNumber: 'Invalid Port Number',
      vaultUrl: 'Invalid Vault URL',
      folderPath: 'Invalid Folder Path',
      saveParameters: 'Save Parameters',
      runHistory: 'Run History',
      archiveTask: 'Archive Scheduled Task',
      archiveChannel: 'Archive Channel',
      runChannel: 'Run channel',
      taskRunHistoryId: 'Task Run History Id',
      frequency: 'Frequency',
      every: 'Every',
      saveDraft: 'Save as draft',
      taskDetailsPage: 'Scheduled Task Details',
      taskStageDetailsPage: 'Scheduled Tasks Run Details',
      cloneChannel: 'Clone Channel',
      close: 'Close',
      channelName: 'Channel Name',
      channelException: 'Channel - Exception',
      fileException: 'File - Exception',
      stageException: 'Stage - Exception',
      channelNameMask:
        'Channel name should only contain letters, numbers, dashes (-), underscores (_), or spaces',
      channelScheduleNameMask:
        'Channel Schedule name should only contain letters, numbers, dashes (-), underscores (_), or spaces',
      editChannel: 'Edit Channel',
      error:
        'Unable to process your request. Please try again later with corrected values.',
      createTaskEdit: 'Edit Scheduled Tasks',
      intervalMask: 'Interval must be a positive number between 1 and 15',
      channelCreated: 'Channel was sucessfully created',
      components: 'Components',
      success: 'Channel was successfully saved',
      successTask: 'Scheduled tasks was successfully saved',
      deleteIcon: 'Channel was successfully deleted',
      runDeleteIcon: 'Scheduled task was successfully archived',
      successFile: 'File was successfully uploaded',
      runIcon: 'Channel was started successfully',
      runTaskIcon: 'Scheduled task was started successfully',
      update: 'Channel was successfully updated',
      updateTask: 'Scheduled tasks was successfully updated',
      scheduletaskStages: 'Schedule Task Stages',
      scheduleCreation: 'Schedule Creation',
      domainName: 'Domain Name',
      parametersError:
        'Parameters not configured for this task! Please try later.',
      parametersLoading: 'Parameters are being loaded',
      parametersLoaded: 'Parameters have been loaded',
      task: 'Task',
      backChannel: 'search results',
      created: 'Creation Date',
      confirmation: 'Are you sure you would like to delete this channel?',
      confirmationTask:
        'Are you sure you would like to archive this scheduled task?',
      confirmationRun: 'Are you sure you would like to run this channel?',
      runTask: 'Run Scheduled Task',
      confirmationRunTask:
        'Are you sure you would like to run this scheduled task?',
      createdBy: 'Created By',
      warning:
        'Warning: Once a channel is archived, this process cannot be undone.',
      warningTask:
        'Warning: Once a scheduled task is archived, this process cannot be undone.',
      warningRun:
        'Warning: Once a channel run is executed, this process cannot be undone.',

      updatedBy: 'Updated By',
      module: 'Module',
      businessPolicy: 'Business Policy',
      fileType: 'File Type',
      schedule: 'Schedule',
      lastRunStatus: 'Last Run Status',
      source: 'Source',
      sortChannels: 'Show channels for:',
      sortTasks: 'Show scheduled tasks for:',
      destination: 'Destination',
      action: 'Action',
      buttons: {
        create: 'Create new channel',
      },
      versionHistory: 'Version History',
      runVersion: 'Run',
      taskVersion: 'Version',
      taskCreator: 'Creator',
      taskCreated: 'Created',
      taskExampleColumn1: 'ExampleColumn1',
      taskExampleColumn2: 'ExampleColumn2',
      taskExampleColumn3: 'ExampleColumn3',
      taskVersionStatus: 'Status',
    },
    workflowEditor: {
      title: 'Workflow Editor',
      entityType: 'Entity Type',
      idType: 'ID Type',
      configList: 'Workflow List',
      workflow: 'Workflow',
      workflowType: 'Workflow Type',
      workflowSubType: 'Workflow Subtype',
      configName: 'Workflow Name',
      configDesc: 'Workflow Description',
      configRecords: 'Workflow Records',
      confirmButton: 'Confirm',
      confirmButtonMsg: 'Remove Row?',
      stepConfigurationRecords: 'Step Configuration Records',
      addStepConfiguration: 'Add Step Configuration',
      addAction: 'Add Action',
      addCondition: 'Add Condition',
      removeConfig: 'Remove Configuration',
      reqValue: 'Required Value',
      schemaId: 'Schema ID',
      required: 'Required',
      addBankruptcyFilingType: 'Add Filing Type',
      addBankruptcyClaimType: 'Add Claim Type',
      addBankruptcyMilestoneType: 'Add Milestone Type',
      closeReason: 'Close Reason',
      addCloseReason: 'Add Close Reason',
      noGroupName: 'No Group Name',
      groupTitles: {
        variantDetails: 'Details',
        actionAttributes: 'Action Attributes',
        workflowTabOptions: 'Tab Options',
        customAttributes: 'Custom Attributes',
        tables: 'Tables',
        closeReasonConfiguration: 'Close Reasons',
        steps: 'Steps',
        bankruptcyClaimType: 'Claim Type',
        bankruptcyFilingType: 'Filing Type',
        bankruptcyMilestoneType: 'Milestone Type',
      },
      stepsFields: {
        title: 'Order',
        orderRequired: 'Order is required',
        groupNameRequired: 'Group Name is required',
        currentStepRequired: 'Current Step is required',
        nextStepRequired: 'Next Step is required',
        manualStep: 'Manual Step',
        automaticStep: 'Automatic Step',
        errorTransitionSteps: 'Select at least one option',
      },
      conditionsFields: {
        title: 'Workflow Conditions',
        conditions: 'Conditions',
        sourceRequired: 'Source is required',
        conditionRequired: 'Condition is required',
        targetRequired: 'Target is required',
      },
      actionsFields: {
        title: 'Workflow Actinos',
        actionRequired: 'Action is required',
        actionParameterRequired: 'Action parameter is required',
        parameter: 'Parameter',
      },
      variantDetailsLabels: {
        description: 'Description',
        type: 'Type',
        typeDescription: 'Type Description',
        workflowSubtype: 'Workflow Subtype',
        workflowSubtypeDescription: 'Workflow Subtype Description',
      },
    },
    configTool: {
      title: 'Configuration Tool',
    },
    configRecordEditor: {
      title: 'Record Editor',
      entityType: 'Entity Type',
      idType: 'ID Type',
      configList: 'Configuration List',
      configName: 'Name',
      configDesc: 'Description',
      configModule: 'Module',
      configRecords: 'Records for',
      confirmButton: 'Confirm',
      confirmButtonMsg: 'Remove Row?',
      coreRecordLegend: 'Core Record (Editable by Landlord only)',
      editIcon: 'Edit Record',
      confirmIcon: 'Confirm Changes',
      cancelIcon: 'Cancel Changes',
      locked: 'Locked',
      editableRecord: 'Editable Record',
      cloneRecord: 'Clone Record',
      viewFields: 'View Fields',
      addRecord: 'Add Record',
      deleteRecord: 'Delete Record',
      noFunctionError: 'Function not implemented.',
      expressionGenerator: {
        title: 'Expression Generator tool',
        functionSelectLabel: 'Functions',
        expressionInput: 'Insert an Expression',
        checkExpression: 'Check Expression',
        saveExpression: 'Save Expression',
        testExpression: 'Test Expression',
        inputPlaceHolder: 'Start typing your expression here...',
        fieldInfo: 'Field Information:',
        expressionResult: 'Expression Result:',
        functionTitle: 'Expression Function List',
        headerCategory: 'Category',
        headerFunctionName: 'Function Name',
        headerSyntax: 'Syntax',
        headerDescription: 'Description',
      },
    },
    configTypeEditor: {
      title: 'Type Editor',
      configTypes: 'Configuration Types',
      attributes: 'Fields',
      attributeName: 'Field Name',
      attributeType: 'Type',
      attributeDesc: 'Description',
      attributeDatasource: 'DataSource',
      attributeDisplayName: 'Display Name',
      attributeDetails: 'Field Details',
      attributeDetailsNew: 'New Field Details',
      attributeValue: 'Field Value',
      attributeSetOOTB: 'Set as Out-of-the-box Field',
      configurationGroupFilter: 'Configuration Group Filters',
      collapseAll: 'Collapse All',
      extensibleBusinessDrivers: 'Field Constraints',
      defaultValue: 'Default Value',
      driverDetails: 'Constraint Details',
      driverName: 'Constraint Name',
      driverNameType: 'Constraint Type',
      driverDataType: 'Constraint Data Type',
      driverValue: 'Constraint Value',
      driverExists: 'Constraint name already exists',
      addDriver: '+ Add Constraint',
      delete: 'Delete',
      cancel: 'Cancel',
      edit: 'Edit',
      expandAll: 'Expand All',
      suggested: 'Suggested: ',
      add: 'Add',
      addType: '+ Add Type',
      addAttribute: '+ Add Field',
      addGroup: '+ Add Group',
      save: 'Save Configuration',
      update: 'Update',
      configType: 'Type',
      configModule: 'Module',
      configDomain: 'Domain',
      configDesc: 'Description',
      configGroup: 'Group',
      confirmButton: 'Confirm',
      confirmButtonMsg: 'Delete Configuration Type?',
      configDetails: 'Configuration Details',
      configTypeGroupName: 'Group',
      configTypeModuleName: 'Module',
      groupName: 'Group Name',
      groupOrder: 'Order',
      groupType: 'Repeating',
      groupDetails: 'Group Details',
      groupDescription: 'Description',
      isNewVersion: 'Save as new version? ',
      repeatingActionLabel: 'Repeating Action Label',
      howToUse: 'How To Use the Configuration Type Editor',
      attributeExists: 'This name already exists in the list',
      configSections: 'Configuration Sections',
      configSectionDetails: 'Section Details',
      configSectionName: 'Section Name',
      addConfigSection: '+ Add Section',
      newConfiguration: 'New Configuration',
      newSection: 'New Section',
      instructions:
        'Please select a Configuration Type to get started, or click the Add button to create a new configuration. Once you have selected an existing configuration type, you may select a section or add a new section. By expanding the section you can see which groups it has inside or add a new group. By expanding a group you can see which fields are inside it. You may add, remove, or edit a field by clicking on the appropriate button. While editing a field, you can add,edit, or remove constraints to it. You can also expand all nodes of the configuration to get a quick overview. You can also view the configuration type details near the top of the screen. After adding, removing, or updating fields/groups/sections, click the Save button to save your changes to the configuration type.',
      groups: 'Field Groups',
      mvpWarning: `NOTE: Currently, once associated records for a type exist, the type's fields, groups, and sections cannot be deleted, nor can their names be modified. Making modifications to a type with existing records will require manual updates to be made to each record.`,
      groupWarning: 'A section should contain at least one field group',
      fieldWarning: 'A group should contain at least one field',
      typeChangesWarning:
        'This configuration type has records associated with it. After making any changes, you will need to go to the record editor and manually update each record to sync the latest changes. ',
      notApplicable: 'N/A',
      coreAttributeLegend: 'Core Field (Editing not allowed)',
      isExpression: 'Is Expression',
      loadingDatasource: 'Loading dataSource...',
      layoutBuilder: {
        title: 'Layouts',
        addLayout: 'Add Layout',
        editLayout: 'Edit Layout',
        expandLayouts: 'Expand Layouts',
        collapseLayouts: 'Collapse Layouts',
        layoutType: 'Layout Type',
        version: 'Version',
        context: 'Context',
      },
    },
    configGroupEditor: {
      title: 'Group Editor',
      configGroups: 'Configuration Groups',
      entityType: 'Entity Type',
      idType: 'ID Type',
      configName: 'Name',
      configDesc: 'Description',
      confirmButton: 'Confirm',
      confirmButtonMsg: 'Remove Row?',
      addGroup: 'Add Group',
      editGroup: 'Edit Group',
      deleteGroup: 'Delete Group',
      coreGroup: 'Core Group (Editable by Landlord only)',
      editableGroup: 'Editable Group',
    },
    printManagement: {
      title: 'Print Management',
      correspondenceTypes: 'Correspondence Type',
      standard: 'Print Group: Standard',
      fti: 'Print Group: FTI',
      printCriteria: 'Print Criteria',
      batchHistory: 'History',
      batchId: 'Batch Print ID',
      records: 'Records',
      pages: 'Pages',
      generatedDate: 'Generated Date',
      status: 'Status',
      overLimit: 'Print Group: Over Limit',
      overLimitFti: 'Print Group: Over Limit FTI',
      correspondenceDetails: 'Correspondence Details',
      outputChannel: 'Output Channel',
      printGroupName: 'Print Group Name',
      printGroupDescription: 'Print Group Description',
      printGroup: 'Print Group',
      lastUpdated: 'Print Group Last Updated',
      currentOverLimit: 'Current Over Limit:',
      message: {
        updatePrintGroup: ' Print Group updated successfully.',
        error: 'Error while updating print group.',
      },
    },
    correspondence: {
      id: 'ID',
      type: 'Details',
      typeId: 'Correspondence Type ID',
      title: 'New Correspondence Type',
      typeCategory: 'Correspondence Type Category',
      contextLevel: 'Correspondence Type Level',
      typeName: 'Correspondence Type Name',
      latestStaus: 'Latest Template Status',
      latestVersion: 'Latest Template Version',
      isActiveTemplate: 'Contains Active Template',
      fti: 'FTI',
      createdBy: 'Created By',
      updatedBy: 'Updated By',
      modifiedDate: 'Modified Date',
      createdDate: 'Created Date',
      updatedDate: 'Updated Date',
      rolesAssociated: 'Associated Roles',
      ftiFlag: 'FTI Correspondence',
      certifiedFlag: 'Certified Mail',
      select: 'Select',
      manageRoles: 'Manage Roles',
      roleManagement: 'Role Management',
      cancel: 'Cancel',
      save: 'Save Changes',
      upload: 'Upload',
      import: 'Import Template',
      export: 'Export History',
      history: 'Template History',
      attributes: 'Template Attributes',
      activate: 'Activate',
      inactivate: 'Inactivate',
      genPreview: 'Generate Preview',
      delete: 'Delete',
      details: 'Correspondence Type Details',
      category: 'Correspondence Type Category',
      freeFormTextPlaceholder: 'Enter Free Form Text',
      suppressionError:
        'No letter generation allowed due to active suppression.',
      freeFormTextMessage:
        'The selected Correspondence Type has "Free Form Text" on it, please enter the text you need it to have.',
      multiChoiceTextMessage:
        'The selected Correspondence Type has "Multi-Choice" on it, please select the needed options.',
      warningMessage:
        'You are about to delete a Draft Template. Are you sure you want to proceed?',
      add: 'Add',
      headerFooter: 'Select your Header/Footer',
      selectOption: 'Select Option',
      selectLetter: 'Select Letter',
      address: 'Primary Address:',
      addressError: 'No address found',
      preview: 'Preview',
      schedule: 'Schedule for Batch',
      batchPdf: 'Generate Batch File',
      printMgmt: 'Print Management',
      proceed: 'Proceed',
      freeFormAndMultiChoice: 'Free Form Text And Multi Choice Selections',
      generate: 'Generate',
      jsonWarning: 'No System Fields have been mapped in Active Dictionary.',
      associatedTemplates: 'Associated Correspondence Types',
      correspondenceData: {
        id: 'Letter ID',
        correspondenceType: 'Correspondence Type Name',
        correspondenceCategory: 'Correspondence Type Category',
        sentTo: 'Sent To',
        status: 'Status',
        printDate: 'Print Date',
        generatedDate: 'Created Date',
        actions: 'Actions',
      },
      message: {
        updateTemplate: 'Template updated successfully.',
        createTemplate: 'Template created successfully.',
        updateTemplateStatus: 'Template status updated successfully.',
        updateDictionary: 'Dictionary details updated successfully.',
        updateCorrespondenceType: 'Correspondence type updated successfully.',
        deleteTemplate: 'Template deleted successfully.',
        deleteCorrespondenceType: 'Correspondence type deleted successfully.',
        createCorrespondenceType: 'Correspondence type created successfully.',
        batchPdfNotFound: 'There are no records found to be batch printed.',
        schedule: 'Scheduled.',
        letterPrint: 'Letter printed locally.',
        errorMessage: 'Correspondence Type Category is required.',
        typeNameRequired: 'Correspondence Type Name is required.',
        typeNameUnique: 'The Correspondence Type Name entered already exist.',
        warningMessage: 'Selected template does not have valid properties.',
      },
      roles: {
        add: 'Add >',
        addAll: 'Add All >>',
        remove: '< Remove',
        removeAll: '<< Remove All',
        available: 'Roles Available',
        assigned: 'Roles Assigned',
        warning: 'The current user does not have authorised roles.',
      },
      cc: {
        recipient: 'Add Recipients (CC)',
        select: 'Select CC',
      },
      workflow: {
        type: 'Workflow Type',
        subType: 'Workflow Subtype',
      },
      previewSteps: {
        title: 'Template Preview',
        fields: 'Fields',
        repeatingFields: 'Repeating Fields',
        freeFormMulti: 'Free Form & Multi Choice',
        occurances: 'Occurances',
        generatePreview: 'Generate Preview',
      },
    },
    attributes: {
      tabName: 'Tab Name',
      tableName: 'Table Name',
      addTab: 'Add Tab',
      addTable: 'Add Table',
      addColumn: 'Add Column',
      addField: 'Add Field',
      removeTab: 'Remove Tab',
      removeTable: 'Remove Table',
      removeColumn: 'Remove Column',
      removeField: 'Remove Field',
      displayName: 'Display Name',
      description: 'Description',
      type: 'Type',
      dataSource: 'Data Source',
      defaultValue: 'Default Value',
      hiddenAttribute: 'Hidden',
      name: 'Name',
      messages: {
        valueReq: 'Required Value',
      },
    },
    letterDetails: {
      title: 'Letter Details',
      letterDetails: 'Correspondence Letter Details',
      requestorDetails: 'Requestor Details',
      letterId: 'Letter ID',
      letterType: 'Letter Type',
      letterTypeCategory: 'Letter Type Category',
      letterTypeId: 'Letter Type ID',
      recipientType: 'Recipient Type',
      templateId: 'Template ID',
      templateVersIon: 'Template Version',
      generatedBy: 'Generated By',
      generatedDate: 'Date Generated',
      datePrinted: 'Date Printed',
      status: 'Status',
      letterSentTo: 'Letter sent to',
      entity: 'Entity',
      Address1: 'Address 1',
      Address2: 'Address 2',
      fti: 'FTI',
      city: 'City',
      state: 'State',
      country: 'Country',
      zip: 'Zip',
      view: 'View',
    },

    templates: {
      title: 'Correspondence Type Manager',
      id: 'Template ID',
      subject: 'Template Name',
      version: 'Version',
      inactive: 'Show Inactive Templates',
      description: 'Description',
      buttons: {
        create: 'Download New Template',
      },
      import: {
        description:
          'Enter a meaningful description which will help you identify the differences between templates in the future and/or help other users know',
        draftMessage:
          'Importing a new template will create/overwrite a draft, the draft could be generated into a new template version',
        templateDescription: 'Template Description',
        warningMessage:
          'Template description should not exceed 2000 characters.',
      },
    },
    downloadTemplate: {
      Header: 'Header',
      Footer: 'Footer',
    },
    reusableContent: {
      title: 'Header and Footer Manager',
      HeaderFooterTitle: 'New Header And Footer',
      typeId: 'ID',
      details: 'Header and Footer Details',
      newRcType: 'Add',
      containsActive: 'Contains Active',
      affectedTemplates: 'Affected Templates',
      history: 'History',
      id: 'ID',
      typeName: 'Name',
      rctypeName: 'Name',
      status: 'Status',
      version: 'Version',
      import: 'Import',
      importRC: 'Import Header/Footer',
      rctypeCategory: 'Category ',
      attributes: 'Attributes',
      createdBy: 'Created By',
      updatedBy: 'Updated By',
      createdDate: 'Creation Date',
      download: 'Download',
      edit: 'Edit',
      delete: 'Delete',
      buttons: {
        create: 'Download New',
      },
      message: {
        createRCType: 'Header/Footer type created successfully.',
        deleteRcType: 'Header/Footer type deleted successfully.',
        updateRCType: 'Header/Footer type updated successfully',
        deleteRCType: 'Header/Footer type deleted successfully',
        createRC: 'Header/Footer created successfully.',
        updateRC: 'Header/Footer updated successfully.',
        updateRCStatus: 'Header/Footer status updated successfully.',
        errorMessage: 'Header/Footer type name is required and unique.',
      },
      RCimport: {
        description:
          'Enter a meaningful description which will help you identify the differences between Herader/Footer in the future and/or help other users know',
        draftMessage:
          'Importing a new Herader/Footer will create/overwrite a draft, the draft could be generated into a new template version',
        rcDescription: 'Description',
        warningMessage:
          'Header/Footer description should not exceed 2000 characters.',
      },
    },
    manageReusableContent: {
      title: 'Create New Content',
      editContent: 'Edit Reusable Content',
      name: 'Content Title',
      type: 'Content Type',
      status: 'Content Status',
      download: 'Download',
      successMessage: 'Content Saved Successfully.',
      networkError: 'Failed due to Network Error',
      history: 'Content History',
      details: 'Content Details',
      errorMessage: 'Please select XML type only',
      newVersion: 'Create New Version',
      category: 'Correspondence Type Category',
      typeName: 'Correspondence Type Name',
      buttons: {
        save: 'Save',
        update: 'Update',
        upload: 'Upload XML',
        newCorrespondenceType: 'New Correspondence Type ',
        activeDictionary: 'Active Dictionary',
        activeTemplates: 'Active Templates',
        cancel: 'Cancel',
        create: 'Create',
      },
      downloadHistory: 'Download History',
      typeNameError: 'Type name is required',
      categoryError: 'Category is required',
      contextError: 'Type level is required',
      maxCharacterLimit: 'Maximum character limit reached',
      alreadyPresentRecord: 'Record for this name is already present',
      noRecordsError: 'There are no records available associated to your role',
      noLetterHistory: 'No letters have been generated',
      workflowGroupRequired: 'Workflow type name is required',
      workflowSubGroupRequired: 'Workflow Sub type name is required',
    },
    activeDictionary: {
      message: {
        createDictionary: 'Dictionary created successfully.',
        errorCreateDictionary: 'Error while creating dictionary.',
        deleteDictionary: 'Dictionary deleted successfully.',
        errordeleteDictionary: 'Error while deleting dictionary.',
        updateDictionaryStatus: 'Dictionary status updated successfully.',
        errorDictionaryStatus: 'Error while updating dictionary status.',
      },
      warningMessage:
        'You are about to delete a Dictionary. Are you sure you want to proceed?',
      warning:
        'Your changes will create/overwrite the a draft dictionary, would you like to proceed?',
      id: 'Dictionary ID',
      editTitle: 'Edit Dictionary',
      title: 'Active Dictionary',
      attributes: 'Dictionary Version Attributes',
      status: 'Status',
      dictionaryVersion: 'Dictionary Version',
      version: 'Version',
      updatedBy: 'Updated By',
      updatedDate: ' Updated Date',
      description: 'Version Description',
      buttons: {
        createDictionary: 'New Dictionary',
        create: 'Create',
        delete: 'Delete',
        activate: 'Activate Dictionary',
        edit: 'Edit Dictionary',
        cancel: 'Cancel',
        save: 'Save',
        groupManagement: 'Group Management',
      },
      activeDescription:
        'The active dictionary gets downloaded to the user’s local machine every time a template is downloaded to ensure that the latest system fields changes are available.',
    },
    manageBatches: {
      alternateId: 'Alternate Batch ID',
      amount: 'Amount',
      associatedDeposit: 'Associated Deposit',
      associatedDepositDetail: 'Associated Deposit detail',
      batchType: 'Batch Type',
      branchLocation: 'Branch Location',
      creationDate: 'Creation Date',
      dateRange: 'Date Range',
      idRange: 'ID Range',
      effectivePostingDate: 'Effective Posting Date',
      extId: 'External Id',
      filterBatches: 'Filter Batches',
      findBatches: 'Find Batches',
      id: 'System Batch ID',
      label: 'Label',
      title: 'Manage Batches',
      message: {
        batchCreated: 'Batch Created',
        batchClosed: 'Batch Closed',
      },
      identifier: 'Identifier',
      name: 'Name',
      navTitle: 'Manage Batches',
      numItems: 'Total Items in Submission',
      openDate: 'Open Date',
      paidDate: 'Paid Date',
      settlementDate: 'Settlement Date',
      createdDate: 'Created Date',
      resetFilters: 'Reset all Filters',
      source: 'Source',
      status: 'Batch Status',
      type: 'Batch Type',
      totalFromDocs: 'Total $ Amount from Documents',
      totalFromPayments: 'Total $ Amount from Payments',
      transactions: 'Transactions',
      transactionCount: 'Transaction Count',
      viewTransactions: 'View',
      availableBatchesError:
        'An error occurred when trying to find available batches.',
    },
    manageFormsProcessing: {
      form: 'Form',
      success: 'Form was successfully saved',
      updated: 'Form was successfully updated',
      cloneForm: 'Form was successfully cloned',
      filled: 'Form was successfully filled',
      failedToCloseBatch: 'Failed to close batch',
      formErrorDialog:
        'This form has errors. If you close the batch, this form will be discarded',
      formCloseConfirmation:
        'Are you sure you want to close the batch? You will no longer be able to submit forms',
      submittingFormToBatch: 'Submitting form to batch.',
      closingBatch: 'Closing Batch',
      closeBatch: 'Close Batch',
      submitAndCloseBatch: 'Submit & Close Batch',
      thisBatchIsClosed: 'This batch is closed',
      understand: 'I understand',
      batchAlreadyClosed:
        'This batch is already closed, you cannot submit additional forms to this batch.',
      clone: 'Clone',
      cancel: 'Cancel',
      save: 'Save',
      navTitle: 'Form Processing',
      latestStatusSummary: 'Latest Status',
      incorrectFileUploaded: ' incorrect file format uploaded',
      startSummary: 'Start',
      latestStatus: {
        new: 'New',
        inProgress: 'In progress',
        error: 'Error',
      },
      start: {
        fileGroupBuilderOptions: 'File Group Builder Options',
        optionOne: 'OPTION 1: ',
        createYourFormTitle: 'Create your own form group',
        createYourFileTitle: 'Create your own file group',
        createYourFormSubtitle: 'Start to build your form group',
        createYourFileSubtitle: 'Start to build your file group',
        createGroupDetails: 'Add group details',
        optionTwo: 'OPTION 2: ',
        uploadTitle: 'Upload',
        uploadFormTitle: 'Upload a XSD file (will create a new file group)',
        uploadFormSubtitle: 'Click here to upload a form',
        uploadByDragFile: 'Click or drag to upload a file',
        browse: 'browse',
        searchTemplateTitle: 'Search an existing form template',
        searchForm: {
          formCategory: 'Form category',
          formYear: 'Form year',
          formName: 'Form name',
        },
        addNewForm: 'Add new form',
      },
      name: 'Name',
      actions: 'Actions',
      addRow: 'Add Row',
      removeRow: 'Remove Row',
      tenantName: 'Tenant Name',
      jurisdiction: 'Jurisdiction',
      location: 'Location',
      year: 'Year',
      filingFrequency: 'Filing Frequency',
    },
    batchDetails: {
      title: 'Batch Details',
      batchIdLabel: 'ID',
      creationDate: 'Creation Date',
      status: 'Status',
      type: 'Type',
      source: 'Source',
      amount: 'Amount',
      paymentAmount: 'Payment Amount',
      remittanceAmount: 'Remittance Amount',
      isBalanced: 'Balanced',
      isOpen: 'Open',
      warningDetails: {
        warningTitle: ' WARNING',
        acceptButton: 'Accept',
        warningDescription: 'Batch will be deleted. This cannot be undone.',
        deleteBatchId: 'BatchId was successfully deleted',
      },
      details: {
        sequence: 'Sequence',
        externalId: 'Alternative/External ID',
        receiveDate: 'Receive Date',
        recordType: 'Record Type',
        documentType: 'Document Type',
        documentId: 'Document ID',
        accountType: 'Account Type',
        accountID: 'Account ID',
        fillingPeriod: 'Filing Period',
        paymentType: 'Payment Type',
        paymentAmount: 'Payment Amount',
        status: 'Status',
        receiptDate: 'Receipt Date',
        batchDetailType: 'Batch Detail Type',
        beginningDln: 'Beginning Dln',
        endingDln: 'Ending Dln',
        periodStart: 'Period Start',
        periodEnd: 'Period End',
        amount: 'Amount',
        batchDetailStatus: 'Batch Detail Status',
        date: 'Date',
        period: 'Period',
        documentAmount: 'Document Amount',
      },
    },
    createAccount: {
      title: 'Create Account',
      navTitle: 'Create Account',
      buttonTitle: 'Add',
      view: 'View Account',
      extendedAttributes: 'Extended Attributes',
      extendedAttrRequired: ' is required',
      select: {
        label: 'Account Type',
        placeholder: 'Choose an option',
      },
      message: {
        success: 'Account created successfully',
        error: 'Error when trying to create an account',
        layoutError: 'This layout is not available at the moment',
        primariesError: 'There is none or more than one primary selected',
        commenceDateError:
          'Demographic(s) Commence Date cannot be before Account Commence Date',
        ceaseDateError: 'Cease Date cannot be on or before Commence Date',
        accountCeaseDateError:
          'Demographic(s) Cease Date cannot be on or before Account Commence Date',
        entityCommenceDateError:
          'Commence Date cannot be before Entity Commence Date',
        filingFrequenciesError:
          'Filing Frequencies dates are not valid. Please review',
      },
      lastStep: 'Confirmation',
    },
    createAsset: {
      title: 'Create Asset',
      navTitle: 'Create Asset',
      buttonTitle: 'Add',
      view: 'View Asset',
      select: {
        label: 'Asset Type',
        placeholder: 'Choose an option',
      },
      message: {
        success: 'Asset created successfully',
        error: 'Error when trying to create an asset',
        layoutError: 'This layout is not available at the moment',
        primariesError: 'There is none or more than one primary selected',
        commenceDateError:
          'Demographic(s) Commence Date cannot be before Asset Commence Date',
        ceaseDateError: 'Cease Date cannot be on or before Commence Date',
      },
      lastStep: 'Confirmation',
    },
    createBatch: {
      title: 'Create Batch',
      editBatchTitle: 'Edit Batch',
      batchLabel: 'Batch Label',
      effectivePostingDate: 'Effective Posting Date',
      paidDate: 'Paid Date',
      branchLocation: 'Branch Location',
      batchType: 'Batch Type',
      saveBatch: 'Create Batch',
      buttons: {
        create: 'Create new batch',
        voidBatch: 'Void Batch',
      },
      helperText: 'This is required field',
    },
    createEntity: {
      title: 'Create Entity',
      navTitle: 'Create Entity',
      view: 'View Entity',
      add: 'Add',
      another: 'Another',
      select: {
        label: 'Entity Type',
        placeholder: 'Choose an option',
      },
      message: {
        success: 'Entity created successfully',
        error: 'An error occurred when trying to create the entity',
        layoutError: 'This layout is not available at the moment',
        primariesError: 'There is none or more than one primary selected',
        commenceDateError:
          'Demographic(s) Commence Date cannot be before Entity Commence Date',
        ceaseDateError: 'Cease Date cannot be on or before Commence Date',
      },
      lastStep: 'Confirmation',
    },
    entitySummary: {
      title: 'Entity Summary',
      navTitle: 'Entity Summary',
      extendedAttributes: 'Extended Attributes',
      sectionTitles: {
        entityDetail: 'Entity Details',
        liabilitySummary: 'Liability Summary',
        correspondence: 'Correspondence',
        relationships: 'Relationships',
        workflows: 'Workflows',
        accounts: 'Accounts',
        auditHistory: 'Audit History',
        activeReports: 'Active Reports',
        suppressions: 'Suppressions',
      },
      actions: {
        add: 'Add',
        edit: 'Edit',
        save: 'Save',
        close: 'Close',
        reset: 'Reset',
        delete: 'Remove',
        back: 'Back to Search',
      },
      header: {
        legalName: 'Legal Name',
        entityId: 'Entity ID',
        type: 'Type',
        commenceDate: 'Commence Date',
        effectiveDate: 'Effective Date',
        mailing: 'Mailing',
      },
      details: {
        isConfidential: 'Confidential',
        identifier: 'ID',
        email: 'Email',
        address: 'Address',
        phone: 'Phone',
        hasMailReturned: 'Has mail returned',
      },
      name: {
        title: 'Name',
        add: 'Add name',
        edit: 'Edit',
        save: 'Save',
        close: 'Close',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        nameSuffix: 'Name suffix',
        type: 'Type',
        isPrimary: 'Is Primary',
        isConfidential: 'Is Confidential',
      },
      address: {
        title: 'Addresses',
        edit: 'Edit',
        close: 'Close',
        attention: 'Attention',
        city: 'City',
        county: 'County',
        country: 'Country',
        postalCode: 'Postal code',
        state: 'State',
        addressLine: 'Address',
        addressLine2: 'Address 2nd',
        type: 'Type',
        isPrimary: 'Is Primary',
        isConfidential: 'Is Confidential',
        hasMailReturned: 'Has Mail Returned',
      },
      identifier: {
        title: 'ID',
        edit: 'Edit',
        close: 'Close',
        type: 'Type',
        value: 'Identifier',
        isPrimary: 'Is Primary',
      },
      emailAddress: {
        title: 'Email addresses',
        edit: 'Edit',
        close: 'Close',
        type: 'Type',
        value: 'Email',
        isPrimary: 'Is Primary',
      },
      phoneNumber: {
        title: 'Phone numbers',
        edit: 'Edit',
        close: 'Close',
        type: 'Type',
        value: 'Phone number',
        isPrimary: 'Is Primary',
      },
      additional: {
        title: 'Additional',
        type: 'Type',
        entityCreationSource: 'Entity creation source',
      },
      auditHistory: {
        executedBy: 'Executed By',
        updateDate: 'Update Date',
        type: 'Type',
        operation: 'Operation',
        noData: 'No Audit Data',
      },
      message: {
        success: 'Entity updated successfully',
        names: 'Name updated successfully',
        emailAddresses: 'Email Address updated successfully',
        addresses: 'Address updated successfully',
        identifiers: 'Identifier updated successfully',
        phoneNumbers: 'Phone Number updated successfully',
        onlyOnePrimary: 'can only have one primary record',
        exactOnePrimary: 'must have one primary record',
        noEntity: 'This entity was not found',
        errorAccountList: 'No accounts for this entity',
        error:
          'An error occurred when trying to update the entity. Please check the data',
        confirmRemoval: 'Please confirm if you want to delete this item',
      },
    },
    dynamicAttributes: {
      lookupsMissing: 'Loading datasource...',
      primaryKey: 'Primary Key',
      hidden: 'Hidden',
    },
    attributeInput: {
      intLow: 'Value too low',
      intHigh: 'Value too high',
      stringLow: 'Value does not meet minimum length',
      stringHigh: 'Value exceeds maximum length',
      dateLow: 'Value does not meet minimum date',
      dateHigh: 'Value exceeds maximum date',
      required: 'Value is required',
    },
    assetSummary: {
      title: 'Asset Summary',
      navTitle: 'Asset Summary',
      extendedAttributes: 'Extended Attributes',
      sectionTitles: {
        assetDetail: 'Asset Details',
        auditHistory: 'Audit History',
        relationships: 'Relationships',
        correspondence: 'Correspondence',
        accounts: 'Accounts',
        workflows: 'Workflows',
        payments: 'Payments',
      },
      actions: {
        add: 'Add',
        edit: 'Edit',
        save: 'Save',
        close: 'Close',
        reset: 'Reset',
        delete: 'Remove',
      },
      header: {
        assetName: 'Asset Name',
        assetId: 'Asset ID',
        address: 'Address',
        type: 'Type',
        effectiveDate: 'Effective Date',
        currentTaxYear: 'Current Tax Year',
      },
      detail: {
        primaryId: 'Primary ID',
        primaryIdType: 'Primary ID Type',
        primaryAddress: 'Primary Address',
        commenceDate: 'Commence date',
      },
      message: {
        asset: 'Asset Name updated successfully',
        addresses: 'Address updated successfully',
        identifiers: 'Identifier updated successfully',
        success: 'Asset updated successfully',
        noAsset: 'This asset was not found',
        onlyOnePrimary: 'can only have one primary record',
        commenceDateError:
          'Demographic(s) Commence Date cannot be before Asset Commence Date',
        ceaseDateError: 'Cease Date cannot be on or before Commence Date',
        errorView: 'An error ocurred when trying to display Asset data',
        error:
          'An error occurred when trying to update the asset. Please check the data',
        confirmRemoval: 'Please confirm if you want to delete this item',
      },
    },
    accountSummary: {
      title: 'Account Summary',
      navTitle: 'Account Summary',
      noAvailablePeriods: 'No Periods Available',
      availablePeriodError: 'Error retrieving periods',
      sectionTitles: {
        accountDetail: 'Account Details',
        auditHistory: 'Audit History',
        correspondence: 'Correspondence',
        workflows: 'Workflows',
        periods: 'Periods',
        suppressions: 'Suppressions',
        bankAccount: 'Bank Accounts',
      },
      actions: {
        add: 'Add',
        edit: 'Edit',
        save: 'Save',
        close: 'Close',
        reset: 'Reset',
        delete: 'Remove',
      },
      header: {
        accountName: 'Account Name',
        accountId: 'Account ID',
        type: 'Type',
        effectiveDate: 'Effective Date',
        commenceDate: 'Commence Date',
      },
      detail: {
        primaryId: 'Primary ID',
        primaryIdType: 'Primary ID Type',
        primaryAddress: 'Primary Address',
        primaryEmail: 'Primary Email',
        primaryPhoneType: 'Primary Phone Type',
        primaryPhone: 'Primary Phone',
        commenceDate: 'Commence date',
      },
      list: {
        type: 'Type',
        status: 'Status',
        name: 'Name',
        id: 'ID',
        address: 'Address',
        startDate: 'Commence Date',
        frequency: 'Frequency',
        city: 'City',
        balance: 'Balance',
      },
      address: {
        title: 'Addresses',
        edit: 'Edit',
        close: 'Close',
        attention: 'Attention',
        city: 'City',
        county: 'County',
        country: 'Country',
        postalCode: 'Postal code',
        state: 'State',
        addressLine: 'Address',
        addressLine2: 'Address 2nd',
        type: 'Type',
        isPrimary: 'Is Primary',
        isConfidential: 'Is Confidential',
        hasMailReturned: 'Has Mail Returned',
      },
      identifier: {
        title: 'ID',
        edit: 'Edit',
        close: 'Close',
        type: 'Type',
        value: 'Identifier',
        isPrimary: 'Is Primary',
      },
      emailAddress: {
        title: 'Email addresses',
        edit: 'Edit',
        close: 'Close',
        type: 'Type',
        value: 'Email',
        isPrimary: 'Is Primary',
      },
      phoneNumber: {
        title: 'Phone numbers',
        edit: 'Edit',
        close: 'Close',
        type: 'Type',
        value: 'Phone number',
        isPrimary: 'Is Primary',
      },
      fillingFrequency: {
        filingFrequency: 'Period Frequency',
      },
      additional: {
        title: 'Additional',
        type: 'Type',
        accountCreationSource: 'Account creation source',
      },
      message: {
        account: 'Account Name updated successfully',
        emailAddresses: 'Email Address updated successfully',
        addresses: 'Address updated successfully',
        identifiers: 'Identifier updated successfully',
        phoneNumbers: 'Phone Number updated successfully',
        success: 'Account updated successfully',
        noAccount: 'This account was not found',
        activeRequired: 'It is required to have one Active',
        activeError: 'This period frequency needs to be Active',
        onlyOnePrimary: 'can only have one primary record',
        exactOnePrimary: 'must have one primary record',
        commenceDateError:
          'Demographic(s) Commence Date cannot be before Account Commence Date',
        ceaseDateError: 'Cease Date cannot be on or before Commence Date',
        confirmRemoval: 'Please confirm if you want to delete this item',
        errorView: 'An error ocurred when trying to display Account data',
        error:
          'An error occurred when trying to update the account. Please check the data',
        createPeriod: 'Period was created successfully',
        createPeriods: 'Periods were created successfully',
        createPeriodError: 'Error saving a period',
      },
      extendedAttributes: 'Extended Attributes',
      createPeriods: 'Create Period(s)',
      addPeriod: 'Add Period',
      periodType: 'Period Type',
      savePeriod: 'Save Period',
      dueDate: 'Due Date',
      endDate: 'End Date',
      startDate: 'Start Date',
      period: 'Period',
      availablePeriods: 'Available Periods',
      filingPeriod: 'Filing Period',
      status: 'Status',
      balance: 'Balance',
      noPeriods: 'No Account Periods',
    },
    bills: {
      title: 'Bills',
      billDetails: 'Bill Details',
      details: 'Details',
      amount: 'Amount',
      noBills: 'No Bills',
      nextStepDate: 'Next Step Date',
      dueDate: 'Due Date',
      issueDate: 'Issue Date',
      PIThruDate: 'P&I Thru Date',
      invoiceNumber: 'Invoice Number',
      billType: 'Bill Type',
      effectiveDate: 'Effective Date',
      billsError: 'Error retrieving bills',
      workflowId: 'Workflow ID',
    },
    periodDetails: {
      title: 'Period Details',
      next: 'Next',
      previous: 'Previous',
      update: 'Update',
      updating: 'Updating',
      updateSuccess: 'Period totals updated',
      updateError: 'Error updating period totals',
      simplifiedTransactionsError: 'Error Retrieving Simplified Transactions',
      pendingAdjustmentsError: 'Error Retrieving Pending Adjustments',
      simplifiedView: 'Simplified View',
      detailedView: 'Detailed View',
      pendingView: 'Pending Adjustments',
      edit: {
        editPeriod: 'Edit Period',
        editPeriodDetails: 'Edit Period Details',
        reasonRequired: 'Reason Required',
        beginDateRequired: 'Begin Date Required',
        endDateRequired: 'End Date Required',
        dueDateRequired: 'Due Date Required',
        successUpdating: 'Updated Period Details',
        errorUpdating: 'Error Updating Period Details',
        beginDate: 'Begin Date',
        endDate: 'End Date',
        dueDate: 'Due Date',
        reason: 'Reason',
        previousPeriod: 'Current Period Dates',
        newPeriod: 'New Period Dates',
        periodReasonsLookupError:
          'Error Retrieving Period Reasons Lookup Types',
        confirmationMessage:
          'Are you sure you want to change the period dates?',
        errorRetrievingExtendedAttributes:
          'Error Retrieving Extended Period Attributes',
      },
      forecast: {
        forecastDate: 'Forecast date',
        balances: 'Balances as of',
      },
      transactions: {
        title: 'Period Transactions',
        titleDetailed: 'Period Transactions (Detailed)',
        titleSimplified: 'Period Transactions (Simplified)',
        transactionId: 'Transaction ID',
        transactionGroup: 'Transaction Group',
        type: 'Type',
        status: 'Status',
        effectiveDate: 'Effective Date',
        processDate: 'Process Date',
        amount: 'Amount',
        noTransactions: 'No Period Transactions',
        noPendingAdjustments: 'No Pending Adjustments',
        createCharge: 'Create Charge',
        add: 'Add',
        transactionType: 'Transaction Type',
        transactionGroupType: 'Transaction Group Type',
        reason: 'Reason',
        required: 'Required',
        manualChargeSuccess: 'Succesfully Created Manual Charge',
        manualChargeError: 'Error Creating Manual Charge',
        manualChargeLookupError: 'Error Retrieving Manual Charge Lookup Types',
        decimalError: 'Amount must be a valid decimal number',
        amountInvalid: 'Please enter a valid amount',
      },
      adjustments: {
        transaction: 'Transaction',
        adjustment: 'Adjustment',
        forPeriod: 'Period',
        adjustmentType: 'Adjustment Type',
        adjustmentReason: 'Adjustment Reason',
        reductionType: 'Reduction Type',
        adjustmentStatus: 'Adjustment Status',
        applyAdjustmentTo: 'Apply Adjustment To',
        impactTypes: 'Impact Types',
        impactType: 'Impact Type',
        errorOnSave: 'Error Creating Adjustment',
        errorAdjustmentOptions: 'Error Retrieving Adjustment Types',
        successOnSave: 'Adjustment Created Successfully',
        confirmationMsg: 'Are you sure you want to make this Adjustment?',
        total: 'Total',
        amount: 'Amount',
        percent: 'Percent',
        edit: 'Edit Adjustment',
      },
      detail: {
        taxPeriod: 'Tax Period',
        periodStartDate: 'Period Start Date',
        periodEndDate: 'Period End Date',
        lastEffectiveDate: 'Last Effective Date',
        periodStatus: 'Period Status',
        dueDate: 'Due Date',
        extendedDueDate: 'Extended Due Date',
        periodTotals: 'Period Totals',
        periodType: 'Period Type',
      },
      totals: {
        title: 'Period Totals',
        liability: 'Liabilities',
        collection: 'Collection',
        credits: 'Credits',
        balance: 'Balance',
        tax: 'Tax',
        penalty: 'Penalty',
        interest: 'Interest',
        totals: 'Totals',
        forecast: 'Forecast',
        noTotals: 'No Period Totals',
      },
    },
    bankAccount: {
      addBankAccount: {
        addAccount: 'Add Bank Account',
        accountNumber: 'Account Number',
        accountNumberConfirmation: 'Account Number Confirmation',
        accountNumberConfirm: 'Please confirm your Account Number',
        accountType: 'Bank Account Type',
        buttonTitle: 'Add Account',
        ceaseDate: 'Cease Date',
        commenceDate: 'Commence Date',
        editAccount: 'Edit Bank Account',
        financialInstitution: 'Name of Financial Institution',
        ownership: 'Ownership',
        isRelatedToActiveWorkItem: 'Related to Active Work Item',
        reason: 'Reason',
        routingNumber: 'Routing Number',
        routingNumberConfirmation: 'Routing Number Confirmation',
        routingNumberConfirm: 'Please confirm your Routing Number',
        allowForRefund: 'Allow For Refund',
        allowForEFT: 'Allow For Electronic Funds Transfer',
      },
      bankAccountData: {
        accountName: 'Name(s) on the account',
        accountType: 'Account Type',
        accountNumber: 'Bank Account Number',
        ceaseDate: 'Cease Date',
        commenceDate: 'Commence Date',
        financialInsitution: 'Name of Financial Institution',
        isRelatedToActiveWorkItem: 'Related to Active Work Item',
        routingNumber: 'Routing  Number',
        status: 'Status',
      },
      bankAccountDetails: {
        title: 'Bank Account Details',
        error: 'An error ocurred when trying to find bank account.',
        lookupError: 'An error ocurred when trying to find id type lookups.',
        bankAccountIdLabel: 'Bank Account Id',
        modifiedDate: 'Modified Date',
        ownership: 'Ownership',
        bankAccountType: 'Bank Account Type',
        financialInstitution: 'Financial Institution',
        dataSourceId: 'Source',
        reason: 'Reason',
        isRelatedToActiveWorkItem: 'Related to Active Work Item',
        updatedBy: 'Modified By',
        allowForRefund: 'Allow For Refund',
        allowForEFT: 'Allow For Electronic Funds Transfer',
      },
      message: {
        createBankAccountError:
          'An error occurred when trying to save the bank account.',
        createBankAccountSuccess: 'Successfully created bank account.',
        editBankAccountError:
          'An error occurred when trying to save the bank account.',
        editBankAccountSuccess: 'Successfully updated bank account.',
        getBankAccountsError:
          'An error occurred when trying to retrieve the bank accounts.',
        ceaseDateError: 'Cease Date cannot be on or before commence date.',
        bankAccountNumberError:
          'Bank Account Number must be between 6 and 17 numeric digits long.',
        bankAccountNotMatch: 'Bank Account Number fields not match.',
        routingNumberError: 'Routing Number must be 9 numeric digits long.',
        routingNumberNotMatch: 'Routing Number fields not match.',
        ownershipError: 'Ownership must only contain letters.',
        required: 'Required',
      },
    },
    refundBatch: {
      title: 'Refund Batches',
      refundBatchSummary: {
        title: 'Refund Batch Summary',
        refundBatchIdLabel: 'Refund Batch Id',
        status: 'Status',
        refundForm: 'Form of Refund',
        refundsCount: 'Count of Refunds',
        refundsTotal: 'Total Refunds',
        createdDate: 'Creation Date',
        processedDate: 'Processed Date',
        prevPageTitle: 'Navigation',
        dropdownTitle: 'Show Refund Batches for: ',
        dropdownOptions: {
          all: 'All Forms of Refund',
          check: 'Check',
          dirDep: 'DirDep',
        },
      },
      refundBatchIdLabel: 'Refund Batch Id',
      status: 'Status',
      creationDate: 'Creation Date',
      processedDate: 'Processed Date',
      refundForm: 'Refund Form',
      refundCount: 'Refund Count',
      refundTotal: 'Refund Total',
      approve: 'Approve',
      refundBatchDetail: {
        title: 'Refund Batch Details',
        error: 'An error occurred when trying to find refund batch.',
        approveError: 'An error occurred when tring to approve refund batch.',
        approveSuccess: 'Successfully approved refund batch.',
        accountName: 'Account Name',
        accountId: 'Account ID',
        accountType: 'Account Type',
        periodEndDate: 'Period End',
        refundAmount: 'Refund Amount',
        approvedDate: 'Approved Date',
        lookupError: 'An error ocurred when trying to find id type lookups.',
        exclude: 'Exclude',
      },
      message: {
        required: 'Required',
      },
    },
    submissionDetails: {
      title: 'Submission Details',
      payments: 'Payments',
      remittances: 'Remittances',
      periods: 'Periods',
      period: 'Period',
      adjust: 'Adjust',
      accountType: 'Account Type',
      adjustRemittances: 'Adjust Remittances',
      submissionId: 'Submission ID',
      noRemittances: 'No Remittances',
      note: 'Note',
      noteRequired: 'Note Required',
      checkNumber: 'Check Number',
      paymentMethodType: 'Payment Method',
      settlementDate: 'Settlement Date',
      amount: 'Amount',
      correctedAmount: 'Corrected Amount',
      paymentAdjustmentType: 'Payment Adjustment Type',
      paymentAdjustmentReason: 'Payment Adjustment Reason',
      adjustmentType: 'Adjustment Type',
      reason: 'Adjustment Reason',
      adjustmentTypesError: 'Error retrieving payment adjustment types',
      adjustmentSuccess: 'Succesfully adjusted the submission',
    },
    paymentDetails: {
      title: 'Payment Details',
      payments: 'Payments',
      paymentType: 'Payment Type',
      reverse: 'Reverse Payment',
      reversalType: 'Reversal Type',
      reversalReason: 'Reversal Reason',
      reversalTypeRequired: 'Reversal Type Required',
      reversalReasonRequired: 'Reversal Reason Required',
      noteRequired: 'Note Required',
      reversalSuccess: 'Payment reversed successfully',
      reversalError: 'Error reversing payment',
      transferError: 'Error transfering payment',
      reversalLookupTypesError: 'Error Retrieving  Reversal Types',
      amount: 'Amount',
      effectiveDate: 'Effective Date',
      primaryIdType: 'Primary ID Type',
      primaryId: 'Primary ID',
      periodCovered: 'Period Covered',
      paymentStatus: 'Payment Status',
      noTransactions: 'No Transactions',
      noInfo: 'No Info',
      noPayments: 'No Payments',
      transactions: 'Transactions',
      currentApplication: 'Current Application',
      editPaymentDetails: 'Edit Payment Details',
      editPaymentDetailsSuccess: 'Updated Payment Details',
      editPaymentDetailsError: 'Error Updating Payment Details',
      viewPaymentDetailsError: 'Payment was not found',
      accountRequired: 'Account Required',
      accountLookup: 'Account Lookup',
      transfer: 'Transfer',
      amountError: 'Transfer cannot exceed total payment amount',
      transferPayment: 'Transfer Payment',
      periodRequired: 'Period Required',
      transferTypesError: 'Error Retrieving Transfer Types',
      confirmTransfer: 'Confirm Transfer',
      transferSuccess: 'Payment Transferred Succesfully',
      selectPeriod: 'Select Period',
      application: {
        accountType: 'Account Type',
        groupType: 'Group Type',
        amount: 'Amount',
        impactType: 'Impact Type',
        periodType: 'Period Type',
        periodBeginDate: 'Period Begin Date',
        periodEndDate: 'Period End Date',
        periodDueDate: 'Period Due Date',
        transfer: 'Transfer',
        balance: 'Balance',
      },
      validation: {
        primaryIdTypeRequired: 'Primary ID Type is required',
        primaryIdRequired: 'Primary ID is required',
        periodCoveredRequired: 'Period Covered Date is required',
        accountTypeRequired: 'Account Type is required',
      },
    },
    relationships: {
      list: {
        source: 'Source',
        destination: 'Destination',
        destinationId: 'Destination ID',
        destinationName: 'Destination Name',
        type: 'Type',
        relationshipType: 'Relationship Type',
        commenceDate: 'Commence Date',
        ceaseDate: 'Cease Date',
        edit: 'Edit',
        delete: 'Delete',
      },
      maintain: 'Relationship Details',
      deleteModalTitle: 'Delete Relationship',
      deleteModalMessage: 'Are you sure you want to delete this relationship?',
      confirmationDeleteMessage: 'Relationship deleted successfully',
      selected: 'Selected',
      entity: 'Entity',
      asset: 'Asset',
      account: 'Account',
      entityToEntity: 'Entity-Entity',
      entityToAsset: 'Entity-Asset',
      assetToEntity: 'Asset-Entity',
      assetToAsset: 'Asset-Asset',
      entityToAccount: 'Entity-Account',
      success: ' Relationship created successfully',
      updateSuccess: ' Relationship updated successfully',
      error: 'An error occurred when trying to create a relationship',
      platformError:
        'No platform configuration found for this relationship type',
      ceaseDateError: 'Cease Date cannot be on or before Commence Date',
      noData: 'No relationships registered',
    },
    manageDeposits: {
      title: 'Manage Deposits',
      navTitle: 'Manage Deposits',
      depositDate: 'Deposit Date',
      depositLabel: 'Deposit Label',
      depositNumber: 'Deposit Number',
      source: 'Source',
      depositAmount: 'Deposit Amount',
      numBatches: '# of Batches',
      numDocs: '# of Docs',
      status: 'Status',
      depositAccount: 'Deposit Account',
      createdBy: 'Created By',
      createdDate: 'Created Date',
      viewDeposit: 'View deposit slip',
      findDeposits: 'Find Deposits',
      depositError: 'Error Retrieving Deposits',
      depositType: 'Deposit Type',
    },
    tableCell: {
      invalidValue: 'Invalid Value',
      invalidDate: 'Invalid Date',
    },
    createDeposit: {
      title: 'Create Deposit',
      findBatches: 'Find Batch(es)',
      enterDepositDetails: 'Enter Deposit Details',
      reviewAndSave: 'Review and Save',
      selectedBatches: 'Selected Batches for Deposit',
      selectBatchesBodyText1:
        'Select batch(es) from the list on the right. Find batches by keywords or dates.',
      selectBatchesBodyText2:
        'You will provide deposit details on the next screen.',
      availableBatches: 'Available Batches',
      createDepositLabel: 'Create Deposit Label',
      depositLabel: 'Deposit Label',
      depositDate: 'Deposit Date',
      bankName: 'Bank Name',
      addNote: 'Add note (optional)',
      deposit: 'Deposit',
      batches: 'Batches',
      batchCount: 'Batch Count',
      depositPaymentAmount: 'Deposit Payment Amount',
      depositTypes: 'Deposit Types',
      depositVoucherNumber: 'Deposit Voucher Number',
      depositSubtotal: 'Deposit Subtotal',
      depositCreated: 'Deposit Created',
      noBatches: 'No batches available',
      dateError: 'Please enter a valid date',
      buttons: {
        create: 'Create new deposit',
      },
      voucherNumber: 'Voucher Number',
    },
    financialEventObject: {
      headerTitle: 'View Financial Event Object',
      formAssociation: 'Form Association',
      attributes: 'Attributes',
      name: 'Name',
      value: 'Value',
      pendingTransactions: 'Pending Transactions',
      postTransactions: 'Post Transactions',
      financialTransactions: 'Financial Transactions',
      type: 'Type',
      effectiveDate: 'Effective Date',
      dueDate: 'Due Date',
      details: 'Details',
      transactionType: 'Transaction Type',
      amount: 'Amount',
      revenuePostDate: 'Revenue Post Date',
      id: 'ID',
      transactionsPosted: 'Transactions Posted',
      financialObjectCreated: 'Financial Event Object Created',
      save: 'Save',
      configure: 'Configure',
      fields: 'Fields',
      field: 'Field',
      impactType: 'Impact Type',
      view: 'View',
      navTitle: 'Create Financial Event Object',
    },
    depositDetails: {
      label: 'Label',
      status: 'Status',
      paidDate: 'Paid Date',
      isBalanced: 'Is Balanced',
      amount: 'Amount',
      transactionCount: 'Transaction Count',
      title: 'Deposit Details',
      depositLabel: 'Deposit Label',
      depositVoucherNumber: 'Deposit Voucher Number',
      numberOfBatches: 'Number of Batches',
      depositDate: 'Deposit Date',
      depositAmount: 'Deposit Amount',
      depositAccount: 'Deposit Account',
      viewDeposit: 'View Deposit Slip',
      createdBy: 'Created By',
      prevPage: 'manage deposits',
      notes: 'Notes',
      save: '    Save',
      saveSuccessful: 'Deposit saved successfully.',
      saveError: 'An error occurred when trying to save deposit.',
      loadDepositDetailsError: 'Error occurred finding deposit details',
      loadAvailableBatchesError: 'Error occurred finding available deposit',
    },
    depositSlip: {
      goBack: 'Go Back',
      print: 'print',
      email: 'email',
      download: 'download',
      check: 'check',
    },
    FinancialTransaction: {
      title: 'View Financial Transaction',
      transactionType: 'Transaction Type',
      amount: 'Amount',
      effectiveDate: 'Effective Date',
      revenuePostDate: 'Revenue Post Date',
      source: 'Source',
      details: 'Details',
      impactType: 'Impact Type',
      dueDate: 'Due Date',
      transactionDetails: 'Transaction Details',
      reverse: 'Reverse Transaction',
      reversalReason: 'Reversal Reason',
      reversalReasonRequired: 'Reversal Reason Required',
      reverseConfirmation: 'Are you sure you want to reverse this transaction?',
      reverseSuccess: 'Transaction reversed successfully',
      reverseError: 'Error reversing transaction',
      reversalLookupError: 'Error Retrieving Reversal Reasons',
      reverseTransactionConfirmButton: 'Confirm',
      error: 'Financial transaction was not found',
    },
    FinancialTransactionDetails: {
      amount: 'Amount',
      AttributesName: 'Attributes',
      AttributesValue: 'Value',
    },
    propertyEdit: {
      title: 'Manage Property',
      propertyOwnerId: 'Property Owner ID',
      propertyId: 'Property ID',
      propertyIdExists: 'Property ID already exists.',
      propertyType: 'Property Type',
      propertySubType: 'Property SubType',
      legalDescription: 'Legal Description',
      geographicalId: 'Geographical ID',
      geographicalIdExists: 'Geographical ID already exists.',
      addOwner: 'Add Owner',
      total: 'Total',
    },
    propertyView: {
      propertyId: 'Property ID',
      geoId: 'Geo ID',
      propertyType: 'Property Type',
      propertySubType: 'SubType',
      year: 'Year',
      liabilitySummary: 'Liability Summary',
      totalUnpaidLiability: 'Total Unpaid Liability',
      liabilityPaid: 'Liability Paid',
      acceptPayment: 'Accept Payment',
      correspondence: 'Correspondence',
      exemptions: 'Exemptions',
      codes: 'Codes',
      fiduciary: 'Fiduciary',
      fiduciaryCode: 'Fiduciary Code',
      effectivePostingDate: 'Effective posting date',
      name: 'Name',
      stitusAddress: 'Stitus Address',
      address: 'Address',
      courtesy: 'Courtesy Copy to Owner',
      notes: 'Notes',
      lastestNote: 'Lastest Note',
      ownerInformation: 'Owner Information',
      ownerId: 'Owner ID',
      ownerPercentage: 'Owner percentage',
      ownerDate: 'Last ownership change date',
      propertySummary: 'Property Summary',
      propertySummaryDetails: 'Property Summary Details',
      legalDescription: 'Legal Description',
      taxStatement: 'Lastest Tax Statement',
      taxableValues: 'Taxable Values - Certified',
      taxYear: 'Which tax year?',
      updateOwner: 'Update Owner',
      ownerHistory: 'Owner History',
      ownerInformationDetails: 'Owner Information Details',
      newOwner: 'New Owner',
      updated: 'Updated',
      taxableValuesDetail: 'Taxable Values Detail',
      exemptionsCredits: 'Exemptions & Credits',
      currentYearDue: 'Current Year Due',
      delinquentDue: 'Delinquent Due',
      totalDue: 'Total Due',
      lastFive: 'Last 5 Correspondence',
      dateSent: 'Date Sent',
      authority: 'Authority',
      roll: '2021 Roll Id 3',
      certified: '2021 Certified',
      interest: 'Interest',
      fees: 'Fees',
      other: 'Other',
      penalties: 'Penalties as of ',
      siteAddress: 'Site Address',
      taxingAuthority: 'Taxing Authority',
      value: 'Value',
      currentValue: 'Most Current Value',
      lastUpdate: 'Last Update',
    },
    propertyOwner: {
      title: 'Manage Property Owner',
      roles: 'Roles',
      addresses: 'Addresses',
      contacts: 'Contacts',
      customAttributes: 'Custom Attributes',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      email: 'Email',
      phoneNumber: 'Phone Number',
      ownerId: 'Owner Id',
      editPropertyOwner: 'Edit Property Owner',
    },
    reporting: {
      title: 'Reports',
    },
    reports: {
      title: 'Reports',
      reportsManager: 'Reports Management',
      reportsDetails: 'Reports Details',
      reportsDetailsButtons: {
        download: 'Download Blank Template',
        addReport: 'Add Report',
        addTemplate: 'Add template',
      },
      SelectError: 'The module is required',
      powerBi: 'Report Designer',
      noTemplates: 'No templates available',
      noReports: 'No reports available',
      cardsHeader: {
        historyTemplate: 'Template History',
        attributesTemplate: 'Template Attributes',
      },
      message: {
        updateReport: 'Report updated successfully',
        createReport: 'Report created successfully',
        deleteReport: 'Report deleted successfully',
        deleteTemplate: 'Template deleted successfully',
        createTemplate: 'Template created successfully',
        activeTemplate: 'Template is activated successfully',
        cloneTemplate: 'Template cloned successfully.',
        deactiveTemplate: 'Template is deactivated successfully',
        updateTemplate: 'Template is updated successfully',
      },
      activeButton: 'Activate',
      deactiveButton: 'Deactivate',
      confirmationDeleteReport:
        'Are you sure you would like to delete this report?',
      confirmationDeleteTemplate:
        'Are you sure you would like to delete this template?',
      reportsManagementHeaderTable: {
        reportId: 'Id',
        reportName: 'Name',
        reportDescription: 'Description',
        hasActiveTemplate: 'Contains Active Template',
        latestVersion: 'Latest Version',
        latestStatus: 'Latest Status',
        createdBy: 'Created By',
        createdDate: 'Created Date',
        updatedBy: 'Updated By',
        updatedDate: 'Updated Date',
        optlocked: 'Locked',
        optDelete: 'Delete',
        optEdit: 'Details',
      },
      reportDetailHeaderTable: {
        templateId: 'Id',
        templateName: 'Name',
        templateDescription: 'Description',
        status: 'Latest Status',
        version: 'Latest Version',
        createdBy: 'Created By',
        createdDate: 'Created Date',
        updatedBy: 'Updated By',
        updatedDate: 'Updated Date',
        iconView: 'View',
        iconEdit: 'Edit',
        iconDelete: 'Delete',
        iconUpdate: 'Update',
        iconDownload: 'Download',
        iconClone: 'Clone',
      },
    },
    dashboards: {
      title: 'Dashboards Management',
      titleManager: 'Report Dashboard',
      dashboardDetails: 'Dashboard Details',
      noDashboards: 'No Dashboards available',
      dashboardsTable: {
        dashboardId: 'Id',
        dashboardName: 'Name',
        pbiDashboardId: 'Pbi Id',
        dashboardDisplayName: 'Display Name',
        dashboardDescription: 'Description',
        status: 'Status',
        optView: 'View',
        optActive: 'Deactivate Or Active',
        optlocked: 'Locked',
      },
      message: {
        updateDashboard: 'Dashboard updated successfully',
        activeDashboards: 'Dashboard is activated successfully',
        deactiveDashboards: 'Dashboard is deactivated successfully',
      },
    },
    bookmarks: {
      title: 'Bookmarks',
    },
    editRole: {
      title: 'Edit Role',
      updateRole: 'Update Role',
      assignedUsers: 'Assigned Users',
      assignedPolicies: 'Assigned Policies',
      userName: 'User Name',
      email: 'Email Address',
      addUsers: 'Add Users',
      selectedForRemoval: 'selected for removal. Update role to confirm',
    },
    editUserRole: {
      title: 'Edit User Role',
      assignedRoles: 'Assigned roles',
      addUser: 'Add User',
      manageUserRoles: 'Manage User Roles',
      availableRoles: 'Available roles',
      roleName: 'Role name',
      description: 'Description',
      module: 'Module',
      startEndDate: 'Start and End Dates',
      rolesAssigned: '# of Roles Assigned',
      textAvailableRoles:
        'Select roles to add to this user from the list. You can preview your selections below before confirming assignment.',
      labelAvailableSelectRoles: 'Selected Roles to Add',
      tenantTimeZone:
        'User role start and end dates are effective immediately and in accordance with {{tenantTimeZone}}. Start and end dates cannot be edited to be in the past.',
      tenantTimeZoneInstructions:
        'If no start and end dates are selected, then roles are effective immediately and in accordance with {{tenantTimeZone}}. Start and end dates cannot be set to a past date.',

      assignSelectedRolesButton: 'Assign selected roles',
      noRolesSelected: 'No roles have been selected yet',
      userName: 'Name',
      email: 'Email',
      subTable: {
        requiredText:
          'Does this role assignment require a start and/or end date?',
        selectText:
          'Select a start and/or end date. At least one date must be selected.',
        startDate: 'Start Date',
        endDate: 'End Date',
        yes: 'Yes',
        no: 'No',
        optional: 'optional',
      },
      changesEffectiveMessage: 'Changes will be effective immediately.',
      sendEmailMessage: 'Send notification email to',
      removeRoles: 'Remove Roles',
      removeRole: 'Remove Role',
      noDateSpecified: 'No date specified',
      availableRolesErrors: {
        dateIsRequired: 'Date is required',
        startDateError: 'Start date cannot be after end date',
        endDateError: 'End date cannot be before start date',
        enterValidDate: 'Enter a valid date value',
        startDateErrorBeforeNow: 'Start date cannot be before current date',
        endDateErrorBeforeNow: 'End date cannot be before current date',
      },
    },
    addUsers: {
      title: 'Edit Role',
      header: 'Add Users',
      subHeader: 'Select one or more users to add',
      select: 'Selected Users',
      userSelected: ' Users selected.',
      unassignedUsers: 'Unassigned Users',
      userName: 'User Name',
      email: 'Email Address',
      addUserBtn: 'Add User',
      addUsersBtn: 'Add Users',
      goBackBtn: 'Go back',
    },
    manageInterestedParty: {
      title: 'Manage Interested Party',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      interestedPartyId: 'Interested Party ID',
      properties: 'Properties',
      interestedPartyIdExists: 'Interested Party ID already exists.',
      correspondenceType: 'Correspondence Type',
      correspondenceEnabled: 'Correspondence Enabled',
    },
    manageCourt: {
      title: 'Manage Court',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      courtName: 'Court Name',
      courtId: 'Court ID',
      courtIdExists: 'Court ID already exists.',
    },
    manageServiceCompany: {
      title: 'Manage Service Company',
      serviceCompanyId: 'Service Company ID',
      serviceCompanyIdExists: 'Service Company ID already exists.',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      serviceCompanyName: 'Service Company Name',
    },
    manageCollectionsAgency: {
      title: 'Manage Collections Agency',
      collectionsAgencyId: 'Collections Agency ID',
      collectionsAgencyIdExists: 'Collections Agency ID already exists.',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
    },
    manageCertifiedServiceProvider: {
      title: 'Manage Certified Service Provider',
      addresses: 'Addresses',
      contacts: 'Contacts',
      emails: 'Emails',
      phoneNumbers: 'Phone Numbers',
      certifiedServiceProviderName: 'Certified Service Provider Name',
      certifiedServiceProviderId: 'Certified Service Provider ID',
      certifiedServiceProviderIdExists:
        'Certified Service Provider ID already exists.',
    },
    search: {
      title: 'Search',
      subtitle: 'Global Search',
      searchtextfield: 'Search',
      searchdropdown: 'Search Type',
      clearBtn: 'Clear',
      searchBtn: 'Search',
      loading: 'Loading...',
      noresult: 'No results found',
      general: 'General',
      amountError: 'Enter a valid decimal amount',
      accountFieldsRequired: 'Account Identifier or Name is required.',
      searchResults: 'Results',
      parameters: 'Parameters',
      chooseOption: 'Choose an option',
      error: 'Enter at least one valid search criteria.',
      propertyOwnerSearch: {
        title: 'Property Owner Search',
        searchTerm: 'Owner Id/Property Id/Name',
        results: {
          title: 'Search Results',
          ownerId: 'Owner Id',
          name: 'Name',
        },
      },
      propertySearch: {
        title: 'Property Search',
        searchTerm: 'Owner Id/Property Id/Street Address',
        results: {
          title: 'Search Results',
          propertyId: 'Property Id',
          type: 'Type',
          subtype: 'Sub Type',
          legal: 'Legal Description',
        },
      },
      fiduciarySearch: {
        title: 'Fiduciary Search',
        searchTerm: 'Fiduciary Id/Name',
        results: {
          title: 'Search Results',
          fiduciaryId: 'Fiduciary Id',
          name: 'Name',
        },
      },
      interestedPartySearch: {
        title: 'Interested Party Search',
        searchTerm: 'Id/Name',
        results: {
          title: 'Search Results',
          interestedPartyId: 'Id',
          name: 'Name',
        },
      },
      serviceCompanySearch: {
        title: 'Service Company Search',
        searchTerm: 'Id/Name',
        results: {
          title: 'Search Results',
          serviceCompanyId: 'Id',
          name: 'Name',
        },
      },
      courtSearch: {
        title: 'Court Search',
        searchTerm: 'Court Id/Name',
        results: {
          title: 'Search Results',
          courtId: 'Id',
          name: 'Name',
        },
      },
      collectionsAgencySearch: {
        title: 'Collections Agency Search',
        searchTerm: 'Id/Name',
        results: {
          title: 'Search Results',
          collectionsAgencyId: 'Id',
          name: 'Name',
        },
      },
      agentSearch: {
        title: 'Agent Search',
        searchTerm: 'Id/Name',
        results: {
          title: 'Search Results',
          agentId: 'Id',
          name: 'Name',
        },
      },
      certifiedServiceProviderSearch: {
        title: 'Certified Service Provider Search',
        searchTerm: 'Certified Service Provider Id',
        results: {
          title: 'Search Results',
          certifiedServiceProviderId: 'Certified Service Provider Id',
          name: 'Name',
        },
      },
      letters: {
        noRecords: 'No records match specific criteria',
      },
    },
    workflow: {
      addAttachment: 'Add Attachment',
      addNote: 'Add Note',
      addNotes: 'Add Notes',
      addSuppression: 'Add Suppression',
      disabledAttachments: 'Case must be created before adding attachments',
      disabledNotesTooltip: 'Case must be created before adding notes',
      addWork: 'Add Work',
      assignNextWorkflowAPI: {
        message: {
          error: {
            general: 'Failed Due To Internal Server Error',
            notFound: 'No Unassigned Work Available.',
          },
          success: 'Successfully Assigned Next Work',
        },
      },
      assignedTable: {
        assignedTitle: 'My Assigned Work',
        priorityIndicator: 'Priority',
        state: 'Step',
        unassignedTitle: 'Unassigned Work',
        variantEndDate: 'End Date',
        variantGroupCode: 'Work Type',
        variantStartDate: 'Start Date',
        workflow_Key: 'Work ID',
        workflowVariantCode: 'Work SubType',
      },
      attributes: 'Attributes',
      create: {
        createNote: 'Create Note',
        createSuppression: 'Create Suppression',
        createWork: 'Create Work',
        noConfigDisplay: 'No work configuration to display',
        title: 'Create',
      },
      cancel: 'Cancel',
      close: 'Close',
      contextInfo: {
        searchContext: 'Search context',
      },
      crm: 'CRM',
      demographics: {
        accountNumber: 'Account number',
        accountType: 'Account type',
        address: 'Address',
        assetType: 'Asset type',
        email: 'Email',
        entityName: 'Entity name',
        entityType: 'Entity type',
        phone: 'Phone',
        periodRange: 'Period range',
      },
      history: 'history',
      isConfidential: 'Confidential',
      attachmentType: 'Attachment Type',
      navTitle: 'Work',
      newNote: 'New Note',
      newSuppression: 'New Suppression',
      newWorkItem: 'New Work',
      noteDetailsTitle: 'Note',
      selectType: 'Select Type',
      selectWork: 'Select Work',
      simpleWorkflowManual: 'Create',
      summaryDetailsTitle: 'Work ID: {{ workId }}',
      subtabs: {
        attachments: 'Attachments',
        letters: 'Letters',
        notes: 'Notes',
        suppressions: 'Suppressions',
      },
      title: 'Work',
      update: {
        saveNote: 'Save Note',
        saveSuppression: 'Save Suppression',
        saveWork: 'Save Work',
        title: 'Update',
      },
      upload: 'Upload',
      tables: 'Tables',
    },
    workManager: {
      title: 'Work Manager',
      export: 'Export',
      workManagerTable: {
        workManagerTitle: 'Work Manager',
        workflow_Key: 'Work ID',
        queueName: 'Queue Name',
        variantGroupCode: 'Work Type',
        workflowVariantCode: 'Work SubType',
        priorityIndicator: 'Priority',
        variantEndDate: 'End Date',
        variantStartDate: 'Start Date',
        state: 'Step',
      },
      messages: {
        unassignSuccess: 'Work item(s) unassigned successfully',
        assignSuccess: 'Work item(s) assigned successfully',
        unassignError: 'Error unassigning work item',
        assignError: 'Error assigning work item',
      },
      historyTable: {
        title: 'Work History',
      },
    },
    approvals: {
      title: 'Approvals',
      approvalsTable: {
        workflow_Key: 'Work ID',
        variantGroupCode: 'Work Type',
        workflowVariantCode: 'Work SubType',
        priorityIndicator: 'Priority',
        variantEndDate: 'End Date',
        variantStartDate: 'Start Date',
        state: 'Step',
      },
      approvalMutation: {
        success: {
          approved: 'Work Was Approved',
          rejected: 'Work Was Rejected',
        },
        error: 'This Action Failed Due To Internal Server Error',
      },
    },
    suppressions: {
      suppressionsTable: {
        title: 'Suppressions',
        id: 'ID',
        subtype: 'SubType',
        reason: 'Reason',
        startDate: 'Start Date',
        endDate: 'End Date',
        state: 'State',
      },
    },
    viewFinancialTransGroup: {
      title: 'View Transaction Group',
      view: 'View',
      viewDetails: 'View Details',
      transactionType: 'Transaction Type',
      amount: 'Amount',
      effectiveDate: 'Effective Date',
      revenuePostDate: 'Revenue Post Date',
      groupType: 'Group Type',
      stage: 'Stage',
      status: 'Status',
      balance: 'Balance',
      findTransactions: 'Find Transactions',
      error: 'Transaction group was not found',
    },
    favorites: {
      title: 'Bookmarks',
      pathName: 'Page Name',
      module: 'Module',
      action: 'Action',
      urlPath: 'Navigation',
      description: 'Description',
      delete: 'delete favorite',
      message: {
        error: 'Error Message',
        success: 'Bookmark was saved',
        remove: 'Bookmark was removed',
      },
    },
    logixTestHarness: {
      navTitle: 'logiX Harness',
      navTitleChannel: 'Channel Logix Harness',
      buttons: {
        clean: 'Clean',
        target: 'Target',
        create: 'Toggle create',
        save: 'Save',
      },
    },
  },
  components: {
    nav: {
      account: 'My Account',
      signout: 'Sign out',
    },
    address: {
      type: 'Type',
      address: 'Address',
      addresses: 'Addresses',
      address1: 'Address Line One',
      address2: 'Address Line Two',
      city: 'City',
      state: 'State/Province',
      zip: 'Zip Code',
      postal: 'Postal Code',
      country: 'Country',
    },
    actions: {
      title: 'Actions',
      entity: 'Entity',
      welcome: 'Welcome',
      edit: 'Edit',
      clone: 'Clone',
      copy: 'Copy',
      run: 'Run',
      remove: 'Remove',
      delete: 'Delete',
    },
    button: {
      save: 'Save',
      add: 'Add',
      addAddress: 'Add Address',
      addOwner: 'Add Owner',
      cancel: 'Cancel',
      search: 'Search',
      undo: 'UNDO',
      update: 'Update',
      addContact: 'Add Contact',
      delete: 'Delete',
      assign: 'Assign',
      unassign: 'Unassign',
    },
    name: {
      name: 'Name',
      given: 'First',
      middle: 'Middle',
      family: 'Last',
      value: 'Name',
    },
    contact: {
      primary: 'Primary',
      type: 'Type',
      email: 'Email',
      phone: 'Phone',
    },
    chatbot: {
      title: 'Chatbot',
      close: 'CLOSE',
    },
    error: {
      title: 'Something went wrong',
      msg: 'Whoops! It looks like we’ve encountered an error. Please try again. If the problem persists, rest assured that someone is working to fix it. You can go to the ',
      signout: 'Sign Out',
    },
    message: {
      success: 'Success',
      warning: 'Warning',
      proceed: 'Proceed',
      info: 'Info',
      networkerror: 'Failed due to Network Error',
      lookupError: 'Lookup configuration service is unavailable',
      configTypeError: 'Configuration Type service is unavailable',
      addressError: 'Entity addresses service is unavailable',
      error: 'Error',
      typeError: 'File type is wrong, the valid format text/csv',
      required: 'Required',
      patternError: 'Field must match pattern',
      noData: 'No Data To Show!',
      noResults: 'No results found.',
      errorFetching: 'Error fetching',
      noDataToDisplay: 'No data to display',
    },
    pagenotfound: {
      title: '404 Not found',
      message: '404: Not Found',
      goto: 'Go to the ',
      dashboard: 'Dashboard',
      or: ' or go ',
      back: 'back',
      towhere: ' to where you were.',
    },
    rowDisplay: {
      // card titles text
      workDetails: 'Work Details',
      workflowVariantDescription: 'Description',
      createdBy: 'Created By',
      createdDate: 'Created Date',
      owner: 'Owner',
      queueName: 'Queue Name',
      status: 'Status',
      state: 'Step',
      closedDate: 'Closed Date',
      variantOwnerName: 'Assigned By',
      variantStartDate: 'Assigned Date',
      variantEndDate: 'End Date',
      workflowVariantName: 'Entity Name',
      // button text
      approveBtn: 'Approve',
      correctBtn: 'Correct',
      rejectBtn: 'Reject',
      updateWorkBtn: 'Update work',
    },
    workManagerActionsCard: {
      title: 'Work Manager Actions',
    },
    userLookupAutocomplete: {
      label: 'Choose a user',
      ownerNameLabel: 'Owner Name',
    },
    titles: {
      workflowInformation: 'Workflow Information',
      demographics: 'Demographics',
    },
  },
};

export default en;
