/* eslint-disable @typescript-eslint/no-explicit-any */
// The WalkMe Player snippet is what allows WalkMe to run on your site.
// Pages or browsers that include the snippet are able to access published WalkMe files
// on a server, allowing end-users to access and play what you create for them
// in the WalkMe Editor.

const initializeWalkMe = () => {
  (window as any)._walkmeConfig = {
    smartTips: true,
    smartWalkthroughs: true,
  };
  ((d, t) => {
    const g: any = d.createElement(t);
    const s: any = d.getElementsByTagName(t)[0];
    g.type = 'text/javascript';
    g.defer = true;
    g.src = `https://cdn.walkme.com/users/${process.env.REACT_APP_WALKME_ACCOUNT}/${process.env.REACT_APP_WALKME_ENVIRONMENT}/${process.env.REACT_APP_WALKME_JS_FILE}`;
    s.parentNode?.insertBefore(g, s);
  })(document, 'script');
};
export default initializeWalkMe;
