import { Error } from 'types/graphqlErrors';
/**
 * This function help you to find the error messages comming from the server,
 * some endpoints are returning an object, some others are returning strings,
 * it can manage both.
 * @param e error delivered by onError()
 * @param message default message in case no error is found
 * @returns message that is found, if not, default message will be returned
 */
const extractMeaningfulMessage = (e: Error[] | unknown, message: string) => {
  /*
    to bear in mind: Error[] interface from graphqlErrors.ts is mostly a guidance
    some endpoints don't comply that shape and include different properties, so this
    function should be flexible.
    e.g. Body is inferred any, that is why the optional chaining operator "?."
    is not enforced by typeScript but so important to fulfill all test cases.
  */
  if (!e) return message;
  let error: Error[];
  let body = null;
  let bodyErrors = null;
  let returnMessage = message;

  if (typeof e === 'object') {
    error = e as Error[];
  } else if (typeof e === 'string') {
    try {
      error = JSON.parse(e);
    } catch {
      return message;
    }
  } else {
    return message;
  }

  const rawBodyTemp = error[0]?.extensions?.response?.body;
  if (typeof rawBodyTemp === 'object') {
    body = rawBodyTemp;
  } else if (typeof rawBodyTemp === 'string') {
    try {
      body = JSON.parse(rawBodyTemp);
    } catch {
      body = null;
    }
  }

  // in doubt plase refer 'test extract messages from body', input2, in errorMessage.test
  if (body && body.errors && body.errors['']) {
    bodyErrors = body.errors[''];
    if (bodyErrors && bodyErrors.length > 0) {
      returnMessage = bodyErrors[0];
      return returnMessage;
    }
  }

  // in doubt plase refer 'test extract messages from body', input1, in errorMessage.test
  if (body && body.errors && !body.errors['']) {
    const messages = [];
    bodyErrors = body.errors;

    for (const key in bodyErrors) {
      const value = bodyErrors[key as unknown as number];
      messages.push(value);
    }
    const msg = messages
      .flat()
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .join('');
    return String(msg);
  }

  // in doubt plase refer 'test extract messages from body', input3, input4, input5, in errorMessage.test
  if (body && body?.message && body?.message !== '') {
    returnMessage = body.message;
  } else if (body && body?.detail && body?.detail !== '') {
    returnMessage = body.detail;
  } else if (error[0]?.message) {
    returnMessage = error[0].message;
  }
  return returnMessage;
};

export default extractMeaningfulMessage;
