/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react';
import { useLogoutApp } from 'hooks/useLogoutApp';

interface AppAutoLogoutProps {
  children: JSX.Element;
}

const events = ['load', 'mousemove', 'click', 'scroll', 'keypress'];

const AppAutoLogout = ({ children }: AppAutoLogoutProps) => {
  const timer: any = useRef();
  const logout = useLogoutApp();

  // this resets the timer if it exists.
  const resetTimer = useCallback(() => {
    if (timer) clearTimeout(timer.current);
  }, []);

  // this function sets the timer that logs out the user after 30 mins
  const handleLogoutTimer = useCallback(() => {
    timer.current = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });

      logout();
      localStorage.setItem('Logout', 'Inactivity');
    }, 30 * 60000);
  }, [logout, resetTimer]);

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, [handleLogoutTimer, resetTimer]);

  return children;
};

export default AppAutoLogout;
