import { useEffect } from 'react';
import { MasterLayoutType } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/layouts';
import { useAppDispatch } from 'redux/hooks';
import { setHeader } from 'redux/contentSlice';
import { useTranslation } from 'react-i18next';
import FormView from 'pages/forms/FormView';

function FormSuspense(): JSX.Element {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.forms.suspenseForm'),
        previousPage: t('pages.forms.formView.backTo'),
        route: 'viewForms',
      })
    );
  }, [dispatch, t]);

  return <FormView context={MasterLayoutType.Suspense} />;
}

export default FormSuspense;
