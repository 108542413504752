import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import datefns from '@date-io/date-fns';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import appTheme, {
  darkTheme,
} from '@revenue-solutions-inc/revxcoreui/material/themes/defaultTheme';
import LayoutInternal from 'components/layout/LayoutInternal';
import AppAutoLogout from 'components/AppAutoLogout';
import Welcome from 'pages/Welcome';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { setModule, setUserName, setUserPreferences } from 'redux/userSlice';
import { useGetUserPreferencesQuery } from 'generated/graphql';
import Loading from 'components/Loading';
import initializeWalkMe from 'snippets/walkme';
import { RootState } from 'redux/store';

function App(): JSX.Element {
  const isAuthenticated = useIsAuthenticated();
  const { i18n } = useTranslation();
  const envName = useSelector<RootState>(
    (state) => state.content.environmentName
  );
  const darkMode = useAppSelector((state) => state.content.darkMode);
  const language = useAppSelector((state) => state.content.language);
  const modules = useAppSelector((state) => state.content.modules);

  const dispatch = useAppDispatch();
  const { accounts } = useMsal();

  const {
    data: userPreferences,
    isError,
    isLoading,
    refetch,
  } = useGetUserPreferencesQuery(
    {},
    { enabled: accounts[0]?.username ? true : false }
  );

  useEffect(() => {
    if (accounts[0]?.username) refetch();
  }, [accounts, refetch]);

  useEffect(() => {
    // check to see if redux user info is
    if (isAuthenticated) {
      if (userPreferences && !isError) {
        // check that in url which module is reloading that one module is set
        const str = window.location.pathname.split('/')[1];
        dispatch(setUserPreferences(userPreferences.GetUserPreferences));
        dispatch(
          setModule(
            modules?.some(
              (item) => item.name?.toLowerCase() === str.toLowerCase()
            )
              ? str
              : userPreferences.GetUserPreferences?.preferredModule ?? 'revenue'
          )
        );
      } else if (isError) {
        dispatch(
          setUserName(accounts[0]?.username ? accounts[0]?.username : '')
        );
      }
    }
  }, [dispatch, userPreferences, isError, accounts, isAuthenticated, modules]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        `${envName}` === 'REF' &&
        window.location.host === 'testdomain.revx.app' &&
        isAuthenticated
      ) {
        initializeWalkMe();
      }
    }, 2900);

    return () => clearTimeout(timer);
  }, [envName, isAuthenticated]);

  return (
    <>
      <ThemeProvider theme={darkMode ? darkTheme : appTheme}>
        <LocalizationProvider dateAdapter={datefns}>
          <CssBaseline />
          <AuthenticatedTemplate>
            <AppAutoLogout>
              {isLoading ? <Loading /> : <LayoutInternal />}
            </AppAutoLogout>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Welcome />
          </UnauthenticatedTemplate>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
