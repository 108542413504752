const pl = {
  configuration: {
    title: 'Useryay Onfigcay',
    lightOption: 'Ightlay',
    darkOption: 'Arkday',
    langEnglish: 'Englishyay',
    langPigLatin: 'Igpay Atinlay',
    langEspanol: 'Español',
    language: 'Language',
  },
  pageTitles: {
    managePropertyOwner: 'Addy Propertyay Owneray',
    manageProperty: 'Adday Propertyay',
  },
  demo: {
    title: 'Emoday',
  },
  welcome: {
    title: 'Elcomeway',
    message: {
      loggedIn: 'Elcomeway otay Ropertypay Remierpay',
      loggedOut: 'Elcomeway otay Ropertypay Remierpay! Leasepay Oglay inyay',
    },
  },
  home: {
    title: 'Omehay',
  },
  search: {
    title: 'Earchsay',
    propertyOwnerSearch: 'ropertypay nerway earchsay',
  },
  reporting: {
    title: 'Eportsray',
  },
  bookmarks: {
    title: 'Ookmarksbay',
  },
  entity: {
    roles: 'Rolesay',
    addresses: 'Addressesyay',
    contacts: 'Contactsay',
    customAttributes: 'Customy Attributesay',
    emails: 'Emailsay',
    phoneNumbers: 'Phoney Numbersay',
    ownerId: 'Owneryay Iday',
  },
  components: {
    nav: {
      account: 'Ymay Accountyay',
    },
    address: {
      type: 'Typeay',
      address1: 'Addressyay Oneyay',
      address2: 'Addressyay Otway',
      city: 'Itycay',
      state: 'Atestay',
      zip: 'Ipzay',
      country: 'Ountrycay',
    },
    actions: {
      title: 'Actionsyaa',
      entity: 'Entityyay',
      welcome: 'Elcomeway',
      save: 'Saveay',
      add: 'Adday',
    },
    name: {
      name: 'Nameay',
      given: 'IrstFa',
      middle: 'IddleMay',
      family: 'AstLay',
      value: 'Amenay',
    },
    contact: {
      primary: 'Primaryay',
      type: 'Typeyay',
      email: 'Emailay',
      phone: 'Phoneay',
    },
  },
};

export default pl;
