import { useState, useEffect } from 'react';

import { Link, Paper } from '@mui/material';
import Header from 'components/layout/Header';
import Sidebar from 'components/layout/Sidebar';
import Routes from 'components/routing/Routes/Routes';
import MessageWrapper from 'components/MessageWrapper';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function LayoutInternal(): JSX.Element {
  const { t } = useTranslation();
  const skipStyle = {
    position: 'absolute',
    left: '-10000px',
    backgroundColor: 'linkBlue.dark',

    ':hover': {
      backgroundColor: 'primary.dark',
    },
    ':focus': {
      top: '5px',
      left: '5px',
      padding: '10px',
      zIndex: 2000,
      textAlign: 'center',
      minWidth: '32px',
      maxWidth: 'fit-content',
      color: 'white.main',
      textTransform: 'capitalize',
      boxShadow: 'none !important',
      borderRadius: '5px',
    },
  };
  const [currentWidth, setCurrentWidth] = useState(80);

  const handleWidth = (arg: number) => {
    setCurrentWidth(arg);
  };

  const messageWrapperStyle = {
    '.MuiAlert-root': {
      ml: `${currentWidth}px`,
      transition: '225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  };

  const location = useLocation();

  useEffect(() => {
    (document.getElementById('emptytag') as HTMLElement).setAttribute(
      'tabindex',
      '-1'
    );
    (document.getElementById('emptytag') as HTMLElement).focus();
    (document.getElementById('emptytag') as HTMLElement).removeAttribute(
      'tabindex'
    );
  }, [location]);

  return (
    <Paper
      sx={{
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: '#E7E4E9',
      }}
    >
      <Link id="emptytag"></Link>
      <Link data-testid="skiptocontent-id" href="#main-content" sx={skipStyle}>
        {t('pages.layout.skipToMain')}
      </Link>
      <Header drawerWidth={currentWidth} open />
      <Box sx={messageWrapperStyle}>
        <MessageWrapper />
      </Box>
      <Sidebar handleWidth={handleWidth} width={currentWidth} />
      <main
        id="main-content"
        style={{
          marginBottom: '4em',
          height: '100%',
          marginTop: '10.5em',
          width: '100%',
          marginLeft: `${currentWidth}px`,
          padding: '1.5rem',
          overflowX: 'hidden',
        }}
        data-testid="layout-id"
      >
        <Routes />
      </main>
    </Paper>
  );
}

export default LayoutInternal;
