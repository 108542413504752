import { useEffect, useState } from 'react';

import Message from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { clearMessage, setAction } from 'redux/messageSlice';

function MessageWrapper() {
  const currentMessage = useAppSelector((state) => state.message);
  const dispatch = useAppDispatch();
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    setShowMessage(true);
  }, [currentMessage]);

  const actionHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setAction(event.currentTarget.id));
  };
  const closeHandler = () => {
    dispatch(clearMessage());
  };
  return (
    <>
      {showMessage && currentMessage.type && (
        <Message
          message={currentMessage.message}
          type={currentMessage.type}
          actionType={currentMessage.actionType}
          onActionClick={actionHandler}
          onCloseClick={closeHandler}
        />
      )}
    </>
  );
}
export default MessageWrapper;
