/* eslint-disable no-console */
import { useState, useEffect } from 'react';

import {
  Configuration,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import Error from 'components/Error';
import Loading from 'components/Loading';
import { gql } from 'graphql-request';
import {
  setModules,
  setScope,
  setEnvironmentName,
  setTenantName,
} from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { TenantCredentialResponse } from 'generated/graphql';
import { setUniqueId } from 'redux/userSlice';
import { v4 as uuid } from 'uuid';

const query = gql`
  query ($domain: String!) {
    TenantCredentials(domain: $domain) {
      clientId
      signUpSignInAuthority
      authorityDomain
      scope
      modules {
        id
        name
      }
      tenantName
      environmentName
    }
  }
`;

function getDomain() {
  if (typeof window !== 'undefined') {
    return window.location.hostname.includes('localhost')
      ? 'uidev.nxg.revenuepremier.com'
      : `${window.location.hostname}`;
  } else return '';
}

interface Props {
  children: React.ReactNode;
}
function Security({ children }: Props): JSX.Element {
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication>();
  const uniqueId = uuid();
  const dispatch = useAppDispatch();
  //using out of the box

  const { data, isError, isLoading } = useQuery<{
    TenantCredentials: TenantCredentialResponse;
  }>(['queryId'], async () => {
    const res = await fetch(`${process.env.REACT_APP_GRAPH}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { domain: getDomain() },
      }),
    });

    const json = await res.json();
    if (json.errors) {
      throw json.errors;
    }

    return json.data;
  });

  useEffect(() => {
    if (!msalInstance && !isLoading && data?.TenantCredentials) {
      dispatch(setScope(data.TenantCredentials.scope));
      dispatch(setEnvironmentName(data.TenantCredentials.environmentName));
      dispatch(setTenantName(data.TenantCredentials.tenantName));
      dispatch(setModules(data.TenantCredentials.modules ?? []));
      dispatch(setUniqueId(uniqueId));
      const elconfig: Configuration = {
        auth: {
          clientId: data.TenantCredentials.clientId,
          authority: data.TenantCredentials.signUpSignInAuthority,
          knownAuthorities: [data.TenantCredentials.authorityDomain],
          redirectUri: `${window.location.origin}/dashboard`,
          postLogoutRedirectUri: `${window.location.origin}/`,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      };
      const newInstance = new PublicClientApplication(elconfig);
      setMsalInstance(newInstance);
    }
  }, [msalInstance, dispatch, isLoading, data, uniqueId]);

  if (isError) {
    return <Error />;
  }
  if (msalInstance) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  }
  return <Loading />;
}

export default Security;
