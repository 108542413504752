import { PaymentDetailsResponse, RemittanceDetail } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { Box, useTheme } from '@mui/system';
import PaymentsIcon from '@mui/icons-material/Payments';
import AdjustRemittance from 'components/AdjustRemittance';

const Translation = () => {
  const { t } = useTranslation();
  return t;
};

export const remittanceColumns: ColumnDef<RemittanceDetail>[] = [
  {
    id: 'checkNumber',
    accessorKey: 'checkNumber',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.('pages.submissionDetails.checkNumber')}
      />
    ),
  },
  {
    id: 'amount',
    accessorKey: 'amount',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.('pages.submissionDetails.amount')}
      />
    ),
    cell: ({ getValue }) => (
      <Box sx={{ width: '3.4em' }}>
        <CurrencyCell
          invalidValue={Translation()?.('pages.tableCell.invalidValue')}
          amountString={getValue() as string}
        />
      </Box>
    ),
  },
  {
    id: 'paymentMethodType',
    accessorKey: 'paymentMethodType',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.(
          'pages.submissionDetails.paymentMethodType'
        )}
      />
    ),
  },
  {
    id: 'settlementDate',
    accessorKey: 'settlementDate',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.('pages.submissionDetails.settlementDate')}
      />
    ),
    cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
  },
];

interface Props {
  remittances: RemittanceDetail[];
  payments: PaymentDetailsResponse[];
}

function RemittanceList({ remittances, payments }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <DataCard
      title={t('pages.submissionDetails.remittances')}
      avatar={<PaymentsIcon sx={{ fill: theme.palette.primary.main }} />}
      action={
        remittances.length > 0 || payments.length > 0 ? (
          <AdjustRemittance remittances={remittances} payments={payments} />
        ) : (
          <></>
        )
      }
      children={
        <>
          {remittances.length > 0 && (
            <DefaultDataTableNext
              data={remittances}
              columns={
                remittanceColumns as ColumnDef<Record<string, unknown>>[]
              }
              enableGlobalFilter={false}
            />
          )}
          {remittances.length === 0 &&
            t('pages.submissionDetails.noRemittances')}
        </>
      }
    />
  );
}

export default RemittanceList;
