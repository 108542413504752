import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  PaymentDetailsResponse,
  RemittanceDetail,
  useGetSubmissionDetailsQuery,
} from 'generated/graphql';
import Loading from 'components/Loading';
import { IHeader, setHeader } from 'redux/contentSlice';
import PaymentList from 'components/PaymentList';
import RemittanceList from 'components/RemittanceList';

function SubmissionDetails() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const module = useAppSelector((state) => state.user.module);
  const { id } = useParams() as { id: string };
  const [payments, setPayments] = useState<PaymentDetailsResponse[]>([]);
  const [remittances, setRemittances] = useState<RemittanceDetail[]>([]);

  const { data, isLoading, isError } = useGetSubmissionDetailsQuery(
    {
      submissionId: id,
    },
    {
      onSuccess: (submissionDetails) => {
        setPayments(submissionDetails.GetSubmissionDetails.payments ?? []);
        setRemittances(
          submissionDetails.GetSubmissionDetails.remittances ?? []
        );
      },
    }
  );

  const getPrevPagePath = useCallback(
    (path: string) => {
      if (path?.includes(module)) {
        return path.replace(module + '/', '');
      } else {
        return path;
      }
    },
    [module]
  );

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: t('pages.submissionDetails.title'),
      previousPage: location?.state?.prevPageName,
      route: getPrevPagePath(location.state?.prevPath.substring(1)),
      data: [],
    };
    dispatch(setHeader(headerData));
  }, [
    data,
    dispatch,
    getPrevPagePath,
    location.state?.prevPageName,
    location.state?.prevPath,
    t,
  ]);

  return (
    <>
      {isLoading && !isError && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <PaymentList
            payments={payments}
            currentPageName={t('pages.submissionDetails.title')}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <RemittanceList remittances={remittances} payments={payments} />
        </Grid>
      </Grid>
    </>
  );
}
export default SubmissionDetails;
