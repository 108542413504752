import { combineReducers } from '@reduxjs/toolkit';
import contentReducer from 'redux/contentSlice';
import messageReducer from 'redux/messageSlice';
import userReducer from 'redux/userSlice';

const rootReducer = combineReducers({
  user: userReducer,
  message: messageReducer,
  content: contentReducer,
});

export default rootReducer;
