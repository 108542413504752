/* eslint-disable no-console */
import { useState, ChangeEvent, useEffect } from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import {
  Card,
  MessageActionType,
  MessageType,
  Message,
} from '@revenue-solutions-inc/revxcoreui';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import RevXBackground from 'assets/revx-background.png';
import { ReactComponent as RevXPowered } from 'assets/revx-powered-by-logo.svg';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

function Welcome(): JSX.Element {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [isDisabled, setIsDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [dipslayMessage, setDisplayMessage] = useState(false);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.welcome.title') }));
    const localDisplayMsg = localStorage.getItem('Logout');
    if (localDisplayMsg) setDisplayMessage(localDisplayMsg === 'Inactivity');
  }, [dispatch, t]);

  const actionHandler = () => {
    localStorage.clear();
    setDisplayMessage(false);
  };

  return (
    <>
      {!isAuthenticated &&
        (inProgress === ('login' as InteractionStatus) ||
          inProgress === ('none' as InteractionStatus)) && (
          <Grid
            container
            spacing={0}
            justifyContent="center"
            style={{ minHeight: '100vh' }}
            sx={{ backgroundImage: `url(${RevXBackground})` }}
            aria-label={t('pages.welcome.revXAppLogin')}
            role="main"
          >
            <Grid item xs={10} md={6} lg={4} xl={4}>
              <Box
                textAlign="center"
                sx={{ marginTop: '4em', marginBottom: '2em' }}
                component="h1"
              >
                <RevXPowered height={100} />
              </Box>
              <Card
                sx={{
                  padding: '2em 0 2em 0',
                  maxWidth: 440,
                  marginX: '1em',
                  backgroundColor: 'primary.main',
                  margin: '0 auto',
                }}
              >
                <FormGroup sx={{ alignItems: 'center' }}>
                  <FormControlLabel
                    sx={{ color: 'white' }}
                    control={
                      <Checkbox
                        sx={{
                          color: 'white.main',
                          '&.Mui-checked': {
                            color: 'white.main',
                          },
                        }}
                        onChange={function (
                          event: ChangeEvent<HTMLInputElement>,
                          checked: boolean
                        ): void {
                          setIsDisabled(!checked);
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: 'inline-block',
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{ color: 'white.main' }}
                        >
                          {t('pages.welcome.agreement')}{' '}
                        </Typography>
                        <Link
                          color="error.light"
                          component="button"
                          variant="body2"
                          onClick={() => {
                            setOpen(true);
                          }}
                          sx={{ lineHeight: 1.5 }}
                        >
                          {t('pages.welcome.terms')}
                        </Link>
                      </Box>
                    }
                  />
                </FormGroup>
                <Collapse in={open}>
                  <Box sx={{ backgroundColor: 'white.main' }}>
                    <Typography
                      variant="h2"
                      sx={{
                        paddingRight: '0.5em',
                        backgroundColor: 'white.main',
                        display: 'inline-block',
                        paddingLeft: '1em',
                        boxShadow:
                          '0px 0px 5px rgb(0 0 0 / 0%), 0px 0px 5px 0px rgb(0 0 0 / 35%) inset;',
                      }}
                    >
                      <Box sx={{ textAlign: 'right', marginBottom: '-2.8em' }}>
                        <IconButton
                          onClick={() => {
                            setOpen(false);
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <Close color="error" />
                        </IconButton>
                      </Box>
                      <p>{t('pages.welcome.warningOne')}</p>
                      <p>{t('pages.welcome.warningTwo')}</p>
                    </Typography>
                  </Box>
                </Collapse>
                <>
                  {dipslayMessage && (
                    <Message
                      type={MessageType.Warning}
                      message={t('pages.welcome.logoutInacivity')}
                      actionType={MessageActionType.Ok}
                      autoHideDuration={12 * 60 * 60000} // Keeping autohide duration as 12 hrs
                      onActionClick={actionHandler}
                      onCloseClick={actionHandler}
                    />
                  )}
                  <Box textAlign="center" sx={{ marginTop: '1em' }}>
                    <Button
                      size="medium"
                      disabled={isDisabled}
                      sx={{
                        width: 120,
                        maxWidth: 120,
                        backgroundColor: '#fa4138',
                        ':hover': { backgroundColor: '#fb7069' },
                      }}
                      id="login"
                      onClick={() => {
                        instance
                          .loginRedirect({
                            scopes: ['openid'],
                          })
                          .catch((e) => {
                            // eslint-disable-next-line no-console
                            console.log(e);
                          });
                      }}
                    >
                      {t('pages.welcome.signIn')}
                    </Button>
                  </Box>
                </>
              </Card>
            </Grid>
          </Grid>
        )}
    </>
  );
}

export default Welcome;
