import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import en from 'localization/en';
import es from 'localization/es';
import pl from 'localization/pl';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      pl: { translation: pl },
      es: { translation: es },
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
